import type { AnyAction } from 'redux'
import { persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/es/storage/session'

import { queryClient } from 'src/api/api-config/queryClient'

// Initial State
const initialState = {
  isLoading: false,
  success: null,
  failed: null
}

// REDUCER TYPE NAME
export const USER_AUTH_REDUCER = 'USER_AUTH_REDUCER'

// Login Reducer Persist Config
const authPersistConfig = {
  key: 'root',
  version: 1,
  storage: sessionStorage,
  whiteList: ['userAuth']
}

function userAuth(state: Record<string, any> = initialState, action: AnyAction) {
  switch (action.type) {
    case `${USER_AUTH_REDUCER}_LOADING`:
      return {
        isLoading: true,
        success: null,
        failed: null
      }
    case `${USER_AUTH_REDUCER}_SUCCESS`:
      queryClient.clear() // Kullanıcı giriş yaptığında önceki önbelleği tamamen temizler.
      return {
        isLoading: false,
        success: action?.payload,
        failed: null
      }
    case `${USER_AUTH_REDUCER}_FAILED`:
      return {
        isLoading: false,
        success: null,
        failed: action.payload
      }
    default:
      return state
  }
}

export default persistReducer(authPersistConfig, userAuth)
