import { store } from 'src/store/store'

import { checkArrayIncludes } from 'src/common/utils/arrayUtils'

const enableRights = process.env?.REACT_APP_ENABLE_RIGHTS

// Check Utils
export const checkPermission = (hasRight, hasProfile) => {
  // Variables
  let rights
  let profiles

  // Store Variables
  const storeAuthStates = store.getState()?.authUser?.success?.data

  if (storeAuthStates?.rightList?.length > 0) {
    rights = storeAuthStates?.rightList
  } else {
    rights = ['']
  }

  if (storeAuthStates?.user?.roleName) {
    profiles = [storeAuthStates?.user?.roleName]
  } else {
    profiles = ['']
  }

  const profilePermission = checkArrayIncludes(hasProfile, profiles)

  const rightPermission = enableRights === 'true' && hasRight ? checkArrayIncludes(hasRight, rights) : true

  if (profilePermission && rightPermission) {
    return true
  }
  return false
}
