import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Layout, Menu } from 'antd'
import { setSidebarStatus } from 'src/store/actions/sidebar/setSidebarStatus'
import { AppDispatch, RootState } from 'src/store/store'

import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'
import { all_users } from 'src/common/constants/roles-rights/roles'

import { checkPermission } from 'src/common/utils/checkPermissionUtils'
import { toggleSidebar } from 'src/common/utils/sidebarToogle'
import { translate } from 'src/common/utils/translateUtil'

import Icon from 'src/components/icon/Icon'
import Tooltip from 'src/components/tooltip/Tooltip'

import ShortcutBar from './_partials/ShortcutBar'

import { MENU as menuConfig } from './menu'

import big_logo from 'src/assets/img/pawder-logo-white.svg'
import small_logo from 'src/assets/img/pawder-min-white.svg'

// Desctruct Antd Components
const { Sider } = Layout

function Sidebar() {
  // Store Variables
  const isSidebarCollapsed = useSelector((state: RootState) => state.sidebarReducer?.isSidebarCollapsed)

  // Variables
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch: AppDispatch = useDispatch()

  const menuLogo = isSidebarCollapsed ? small_logo : big_logo
  const logoStyle = isSidebarCollapsed ? 'minimal' : 'full'

  // Default Selected
  const menuDefaultSelectHandler = (): string[][] => {
    const parent = menuConfig?.find((menuItem) => menuItem?.url === location?.pathname)
    if (parent) {
      return [[parent?.key]]
    }
    const hasChildrenParents = menuConfig?.filter((menuItem) => menuItem?.children)
    const selectedItem: any = hasChildrenParents?.map(
      (menuItem) => menuItem?.children?.find((childItem) => childItem?.url === location?.pathname)
    )

    if (selectedItem?.[0]?.key) {
      const openSubMenu = hasChildrenParents?.find(
        (item) => item?.children?.map((subItem) => subItem?.url === selectedItem?.[0]?.key)
      )

      return [selectedItem?.[0]?.key, [openSubMenu?.key]]
    }
    return []
  }

  // Menu Generator Function
  const menuData = useMemo((): any => {
    const itemCreator = (item) => {
      if (checkPermission(item?.hasRight, item?.hasProfile ? item?.hasProfile : all_users)) {
        return {
          title: translate(item?.title),
          label: <span> {translate(item?.title)}</span>,
          key: item?.key,
          id: item?.key,
          icon: !isSidebarCollapsed ? (
            <Icon size="lg" icon={item?.icon} status={ICON_STATUS.WHITE} />
          ) : (
            <Tooltip title={item?.title} placement="right" overlayClassName="menu-item-tooltip">
              <Icon size="lg" icon={item?.icon} status={ICON_STATUS.WHITE} />
            </Tooltip>
          ),
          url: item?.url
        }
      }

      return null
    }
    const itemCreatorWithChildren = (item) => {
      if (checkPermission(item?.hasRight, item?.hasProfile ? item?.hasProfile : all_users)) {
        return {
          ...itemCreator(item),
          children: item?.children ? item?.children?.map((item) => itemCreator(item)) : undefined
        }
      }
      return null
    }
    return Array.isArray(menuConfig)
      ? menuConfig?.map((menuItem: Record<string, any>) => {
          if (checkPermission(menuItem?.hasRight, menuItem?.hasProfile ? menuItem?.hasProfile : all_users)) {
            return {
              ...itemCreator(menuItem),
              children: menuItem?.children
                ? menuItem?.children?.map((item) => itemCreatorWithChildren(item))
                : undefined
            }
          }
          return null
        })
      : []
  }, [])

  // Actions
  const onPageChange = (page) => {
    const clickedItem = menuData?.find((item) => item?.key === page?.key)
    if (clickedItem) {
      navigate(clickedItem?.url)
    } else {
      const myParent = menuData?.find((item) => item?.key === page?.keyPath?.[1])

      if (myParent) {
        const myConfig = myParent?.children?.find((item) => item?.key === page?.key)
        myConfig && navigate(myConfig?.url)
      } else {
        const myChildParent = menuData?.find((item) => item?.key === page?.keyPath?.[2])
        if (myChildParent) {
          const myParent = myChildParent?.children?.find((item) => item?.key === page?.keyPath?.[1])

          if (myParent) {
            const myConfig = myParent?.children?.find((item) => item?.key === page?.key)
            myConfig && navigate(myConfig?.url)
          }
        }
      }
    }
  }

  // useEffects
  useEffect(() => {
    if (isSidebarCollapsed) {
      toggleSidebar(isSidebarCollapsed)
    }
  }, [])

  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body').item(0) as HTMLElement

    if (isSidebarCollapsed) {
      bodyEl.classList.remove('remove-menu-tooltip')
    } else {
      bodyEl.classList.add('remove-menu-tooltip')
    }
  }, [isSidebarCollapsed])

  return (
    <>
      <ShortcutBar active />
      <Sider theme="light" collapsible className="app-sidebar" id="app-sidebar">
        <div className="sidebar-trigger-small">
          <Icon
            size="xl"
            icon={ICON_LIST.CLOSE}
            onClick={() => {
              dispatch(setSidebarStatus(true))
            }}
            status={ICON_STATUS.WHITE}
          />
        </div>

        <div className="sidebar-brand">
          <div className={`brand-img ${logoStyle}`}>
            <img src={menuLogo} alt="brand" onClick={() => navigate('/')} />
          </div>
        </div>

        <Menu
          forceSubMenuRender
          defaultSelectedKeys={menuDefaultSelectHandler()?.[0]}
          defaultOpenKeys={menuDefaultSelectHandler()?.[1] || []}
          theme="light"
          onClick={onPageChange}
          mode="inline"
          items={menuData}
        />
      </Sider>
    </>
  )
}

export default Sidebar
