// Import Icons
import { ArrowLeft, ArrowRight, Bell, Edit, En, Sun, Tr, Uk } from 'src/components/Icons'

export const CUSTOM_ICON_LIST = {
  ARROW_LEFT: ArrowLeft(),
  ARROW_RIGHT: ArrowRight(),
  EDIT: Edit(),
  TR: Tr(),
  UK: Uk(),
  BELL: Bell(),
  EN: En(),
  SUN: Sun()
}
