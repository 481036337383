import { useNavigate } from 'react-router-dom'

import { STATUS } from 'src/common/constants/status/status'

import Button, { ButtonProps } from 'src/components/cta/button/Button'

export interface LinkProps extends ButtonProps {
  label: string
  url: string
  status?: STATUS
}

function Link(props: LinkProps) {
  // Desctruct Props
  const { label, url, status } = props

  // Variables
  const navigate = useNavigate()

  return <Button {...props} label={label} block status={status} onClick={() => navigate(`${url}`)} />
}

export default Link

Link.defaultProps = {
  status: STATUS.LINK
}
