import { useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import { ConfigProvider } from 'antd'
import enUs from 'antd/lib/locale/en_US'
import trTR from 'antd/lib/locale/tr_TR'
import i18n from 'src/common/locales/i18n'

// import PrivateRoute from 'src/components/authentication/private-route/PrivateRoute'
import './assets/styles/App.scss'
import './assets/styles/base.scss'
import './assets/styles/global.scss'
import { getTheme } from './common/utils/getTheme'
import PrivateRouteDev from './components/authentication/private-route/PrivateRouteDev'

function App() {
  // Variables
  const AUTH_CONTROL_STATUS = process.env.REACT_APP_AUTHENTICATION

  // useEffect
  useEffect(() => {
    getTheme()
  }, [])

  return (
    <ConfigProvider locale={i18n?.language === 'tr' ? trTR : enUs} key={i18n?.language}>
      {AUTH_CONTROL_STATUS === 'true' && <PrivateRouteDev />}
    </ConfigProvider>
  )
}
export default withTranslation()(App)
