import * as types from 'src/store/actions/storeTypes'

import { toggleSidebar } from 'src/common/utils/sidebarToogle'

const initialState = {
  isSidebarCollapsed: true
}

export default function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case types.SIDEBAR_STATUS_PASSIVE:
      toggleSidebar(true)
      return {
        ...state,
        isSidebarCollapsed: true
      }
    case types.SIDEBAR_STATUS_ACTIVE:
      toggleSidebar(false)
      return {
        ...state,
        isSidebarCollapsed: false
      }
    default:
      return state
  }
}
