import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Login from 'src/pages/login/Login'
import { RootState } from 'src/store/store'

import { LOADING_TYPES } from 'src/common/constants/loading/loadingTypes'

import { routeGenerator } from 'src/common/utils/routeGenerator'

import Loading from 'src/components/loading/Loading'

const AUTH_CONTROL_STATUS = process.env.REACT_APP_AUTHENTICATION === 'true'

const PrivateRouteDev = () => {
  // useStates
  const [template, setTemplate] = useState<JSX.Element>(<Loading loading loadingType={LOADING_TYPES.FULLSCREEN} />)

  // Variables
  const loggedUser = useSelector((state: RootState) => state?.authUser?.success?.data)
  const loggedUserLoading = useSelector((state: RootState) => state?.authUser?.isLoading)

  // Handler
  const handler = (loading, loggedUser) => {
    // Kullanıcı bilgileri için istek sonuçlanmadıysa loading render et
    if (loading) {
      return <Loading loading loadingType={LOADING_TYPES.FULLSCREEN} />
    }

    // Kullanıcı bilgileri getirildiyse routeları yetkilere göre genarate et veya login döndür.
    return routeGenerator(
      loggedUser?.user?.roleName,
      loggedUser?.rightList,
      AUTH_CONTROL_STATUS && loggedUser ? undefined : <Login />
    )
  }

  useEffect(() => {
    setTemplate(handler(loggedUserLoading, loggedUser))
  }, [loggedUser, loggedUserLoading])

  useEffect(() => {
    if (loggedUser) {
      console.log('logged')

      sessionStorage.setItem('jwtToken', loggedUser?.token)
      sessionStorage.setItem('user', JSON.stringify(loggedUser?.user))
    }
  }, [loggedUser])

  return template
}

export default PrivateRouteDev
