import type { AnyAction } from 'redux'

// Initial State
const initialState = {
  isLoading: false,
  success: null,
  failed: null
}

// REDUCER TYPE NAME
export const EXAMPLE_REDUCER_NAME = 'LOGIN_FETCH_REDUCER'

export default function ExampleReducer(state: Record<string, any> = initialState, action: AnyAction) {
  const modifiedData = action.payload
  switch (action.type) {
    case `${EXAMPLE_REDUCER_NAME}_LOADING`:
      return {
        isLoading: true,
        success: null,
        failed: null
      }
    case `${EXAMPLE_REDUCER_NAME}_SUCCESS`:
      // * Bu kısımda gelen datayı istediğin gibi manipüle et ve success'e ver.
      return {
        isLoading: false,
        success: modifiedData,
        failed: null
      }
    case `${EXAMPLE_REDUCER_NAME}_FAILED`:
      return {
        isLoading: false,
        success: null,
        failed: action.payload
      }
    default:
      return state
  }
}
