import * as React from 'react'

function SvgArrowRight(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 12 22" {...props}>
      <path d="M1 1l10 10L1 21" stroke="currentColor" fill="none" fillRule="evenodd" strokeLinecap="round" />
    </svg>
  )
}

export default SvgArrowRight
