/* eslint-plugin-disable  jsx-a11y */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { setAddShortcutModalVisible } from 'src/store/actions/sidebar/setAddShortcutModalVisible'

import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'

import { translate } from 'src/common/utils/translateUtil'

import Icon from 'src/components/icon/Icon'

import ShortcutBarForm from './ShortcutBarForm'

// import { ChromePicker } from 'react-color';

interface ShortcutProps {
  active: boolean
}

// Types
interface IconOption {
  value: number
  label: JSX.Element
}

export interface ShortcutsType {
  id: number
  color: string
  url: string
  icon: any
  textColor: string
}

function ShortcutBar(props: ShortcutProps) {
  const navigate = useNavigate()

  // Store Variables
  const dispatch: any = useDispatch()

  const { active } = props
  // State Variables
  const [icons, setIcons] = useState<IconOption[]>([])
  const [shortcuts, setShortcuts] = useState<ShortcutsType[]>([])
  const [shortcutCounter, setShortcutCounter] = useState(0)
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [isEditModActive, setIsEditModActive] = useState(false)
  const [editShortcutModalVisible, setEditShortcutModalVisible] = useState(false)

  const [selectedShortcut, setSelectedShortcut] = useState<ShortcutsType>()
  const [picked, setPicked] = useState({
    color: '',
    textColor: '',
    url: '',
    icon: undefined
  })
  // Form Props
  const formProps = {
    shortcuts,
    setShortcuts,
    setShortcutCounter,
    shortcutCounter,
    picked,
    setPicked,
    editShortcutModalVisible,
    selectedShortcut,
    setEditShortcutModalVisible,
    displayColorPicker,
    setDisplayColorPicker,
    icons
  }

  // Use Effects
  useEffect(() => {
    if (!active || shortcuts.length < 1) {
      document.querySelector('.ant-layout-sider-children')?.classList.add('shortcut-bar-deactive')
      document.querySelector('.ant-layout-sider-has-trigger')?.classList.add('shortcut-bar-deactive')
      document.querySelector('.header_and_content')?.classList.add('shortcut-bar-deactive')
      document.querySelector('.shortcut-bar')?.classList.add('shortcut-bar-deactive')
    } else {
      document.querySelector('.ant-layout-sider-has-trigger')?.classList.remove('shortcut-bar-deactive')
      document.querySelector('.ant-layout-sider-children')?.classList.remove('shortcut-bar-deactive')
      document.querySelector('.header_and_content')?.classList.remove('shortcut-bar-deactive')
      document.querySelector('.shortcut-bar')?.classList.remove('shortcut-bar-deactive')
    }
  }, [active, shortcuts.length])
  useEffect(() => {
    const storedShortcuts = JSON.parse(localStorage.getItem('shortcuts') || '[]')
    const shortcutsToState = storedShortcuts.map((shortcut) => ({
      ...shortcut,
      icon: shortcut?.icon?.props?.icon && (
        <Icon icon={shortcut?.icon?.props?.icon} style={{ color: shortcut?.icon?.props?.style?.color }} />
      )
    }))
    setShortcuts(shortcutsToState)
  }, [])

  useEffect(() => {
    localStorage.setItem('shortcuts', JSON.stringify(shortcuts))
  }, [shortcuts])
  useEffect(() => {
    let iconList = [] as IconOption[]
    for (let i = 0; i < Object.values(ICON_LIST).length; i++) {
      iconList = [
        ...iconList,
        {
          value: i + 1,
          label: <Icon icon={Object.values(ICON_LIST)[i]} status={ICON_STATUS.PRIMARY} size="lg" />
        }
      ]
    }

    setIcons([
      {
        value: 0,
        label: <span>{translate('GLOBAL.COMPONENTS.SELECT.VALUES.NONE')}</span>
      },
      ...iconList
    ])
  }, [])

  return (
    <div>
      {active ? (
        <div className="shortcut-bar">
          <div className="top">
            {shortcuts.map((sc, index) => (
              <div
                key={index}
                className={`${isEditModActive ? 'edit-mode' : ''} shortcut-box `}
                style={{ backgroundColor: sc.color, color: sc.textColor }}
                onClick={
                  !isEditModActive
                    ? () => {
                        window.onclick = (e) => {
                          if (e.target.closest('.shortcut-box')) {
                            navigate(sc.url)
                          }
                        }
                      }
                    : () => {}
                }
              >
                {sc?.icon ? sc?.icon : <span>{sc?.url[1]?.toLocaleUpperCase()}</span>}

                {/* Edit Button */}
                {isEditModActive && (
                  <div
                    className="edit-box"
                    onClick={() => {
                      setEditShortcutModalVisible(true)
                      setSelectedShortcut(sc)
                      setPicked({
                        color: sc?.color,
                        textColor: sc?.textColor,
                        url: sc?.url,
                        icon: sc?.icon
                      })
                    }}
                  >
                    <Icon
                      name="remove-box"
                      icon={ICON_LIST.EDIT}
                      status={ICON_STATUS.SECONDARY}
                      size="xs"
                      onClick={() => true}
                    />
                  </div>
                )}
              </div>
            ))}

            {/* + Button (Add Shortcut) */}
            {isEditModActive && shortcutCounter < 5 && (
              <div
                className="add-shortcut"
                onClick={() => {
                  dispatch(setAddShortcutModalVisible(true))
                }}
              >
                <Icon icon={ICON_LIST.PLUS} status={ICON_STATUS.WHITE} size="1x" />
              </div>
            )}
          </div>
          <div className="bottom">
            <div
              className="edit-shortcut"
              onClick={() => {
                setIsEditModActive(!isEditModActive)
                setShortcutCounter(shortcuts.length)
              }}
            >
              <Icon
                icon={isEditModActive ? ICON_LIST.CLOSE : ICON_LIST.ELLIPSIS_VERTICAL}
                status={ICON_STATUS.WHITE}
                size="xl"
              />
            </div>
          </div>

          {/* Add Shortcut Modal */}
          <ShortcutBarForm mode="add" {...formProps} />

          {/* Edit Shortcut Modal */}
          <ShortcutBarForm mode="edit" {...formProps} />
        </div>
      ) : (
        <div className="shortcut-bar deactive" />
      )}
    </div>
  )
}

export default ShortcutBar
