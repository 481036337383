import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'

import DefaultLayout from 'src/layouts/DefaultLayout'
import InvalidAuthorization from 'src/pages/error-pages/invalid-authorization/InvalidAuthorization'
import NotFound from 'src/pages/error-pages/not-found/NotFound'
import UserNotFound from 'src/pages/error-pages/user-not-found/UserNotFound'
import { routes } from 'src/routes/routes'

import { checkArrayIncludes } from 'src/common/utils/arrayUtils'

import Loading from 'src/components/loading/Loading'

import { LOADING_TYPES } from '../constants/loading/loadingTypes'

export const routeGenerator = (roleName, rightList, RedirectPage?: JSX.Element) => {
  // Route Arrays Init
  const legalPages: JSX.Element[] = []
  const failPages: JSX.Element[] = []

  // Variables
  const enableRights = process.env.REACT_APP_ENABLE_RIGHTS === 'true'

  if (RedirectPage != null) {
    routes?.forEach((route, index) => {
      failPages?.push(<Route key={`route_${index}`} path={route?.path} element={RedirectPage} />)
    })
  } else {
    routes?.forEach((route, index) => {
      // Role & Right Conditions
      const profileCondition = enableRights ? checkArrayIncludes(route?.hasProfile, [roleName]) : true
      const rightCondition =
        enableRights && route?.hasRight != null ? checkArrayIncludes(route?.hasRight, rightList) : true

      // Component Name Capitalize
      const Component = route?.component

      // Routes classification
      if (profileCondition && rightCondition) {
        legalPages?.push(
          <Route
            key={`route_${index}`}
            path={route?.path}
            element={
              <Suspense fallback={<Loading loading loadingType={LOADING_TYPES.FULLSCREEN} />}>
                <Component />
              </Suspense>
            }
          />
        )
      } else {
        failPages?.push(<Route key={`route_${index}`} path={route?.path} element={<InvalidAuthorization />} />)
      }
    })
  }

  // Generate Routes
  const appRoutes = (
    <Routes>
      {legalPages?.length && (
        <Route path="/" element={<DefaultLayout />}>
          {legalPages?.map((route) => route)}
        </Route>
      )}
      {failPages?.map((route) => route)}

      {/* Sistemin health check urldir */}
      <Route path="/hc" element={<>OK</>} />
      {/* Sistemin notfound urldir */}
      <Route path="/kullanici-bulunamadi" element={<UserNotFound />} />
      {/* Sistemin yetkisiz kullanıcı urldir */}
      <Route path="/yetkisiz-kullanici" element={<InvalidAuthorization />} />
      <Route path="/unexpected" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )

  return appRoutes
}
