import { stampToDate } from './dateTimeUtils'
import { removeAccents } from './stringUtil'

export const arrayFindIndexById = (arr, filterKey) => {
  const result = arr?.findIndex((item) => item?.id === filterKey)

  return result
}
export const arrayFindNameById = (arr, filterKey) => {
  let result = ''

  arr?.map((item) => item?.id === filterKey && (result = item?.name))

  return result
}

export const arrayFindTypeByName = (arr, filterKey) => {
  let result = null

  arr?.map((item) => item?.name === filterKey && (result = item?.type))

  return result
}

export const arrayReformatWithSameKey = (data: any[], key: string, arrayName: string) => {
  // listenin içinde aynı keyler varsa onları birleştiriyoruz.
  const newList = data.reduce((acc, curr) => {
    const found = acc.find((item) => item[key] === curr[key])
    if (found) {
      found.activities.push(curr)
    } else {
      acc.push({
        [key]: curr[key],
        [arrayName]: [curr]
      })
    }
    return acc
  }, [])

  return newList
}

export const arrayFindIndexByAny = (arr, mainKey, filterKey) => {
  const result = arr?.findIndex((item) => item?.[mainKey] === filterKey)

  return result
}

export const isArray = (value) => {
  const result = Array?.isArray(value)

  return result
}

export function getUniqueListBy(arr: any, key: any): any {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export function sortAscByKey(array, key) {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]

    return x < y ? -1 : x > y ? 1 : 0
  })
}
export function sortDescByKey(array, key) {
  return array.sort((a, b) => {
    const x = a[key]
    const y = b[key]

    return x > y ? -1 : x < y ? 1 : 0
  })
}

export function sortDateAscByKey(array, key) {
  return array.sort((a, b) => {
    const x = stampToDate(a[key])
    const y = stampToDate(b[key])

    return x < y ? -1 : x > y ? 1 : 0
  })
}

export function sortDateDescByKey(array, key) {
  return array.sort((a, b) => {
    const x = stampToDate(a[key])
    const y = stampToDate(b[key])

    return x > y ? -1 : x < y ? 1 : 0
  })
}

export function countByKey(array, key, key2) {
  let counter = 0

  for (let i = 0; i < array.length; i++) {
    if (removeAccents(array[i]?.[key]) === removeAccents(key2)) {
      counter += 1
    }
  }

  return counter
}

export function countByFind(array, key, key2) {
  let counter = 0

  for (let i = 0; i < array.length; i++) {
    if (array[i]?.[key]?.includes(key2)) {
      counter += 1
    }
  }

  return counter
}

export const removeItemFromArrayById = (arr, filterKey) => {
  const result = arr.filter((item) => item.id !== filterKey)

  return result
}

export const checkArrayIncludes = (controlArray, checkArray) =>
  checkArray?.some?.((controlItem) => controlArray?.includes?.(controlItem))
