import { Input as InputAntd } from 'antd'
import { InputProps as InputPropsAntd } from 'antd/lib/input'

import { translate } from 'src/common/utils/translateUtil'

import FormItem, { FormItemProps } from 'src/components/form-elements/form-item/FormItem'

export type MergedProps = Omit<InputPropsAntd, 'name'> & Omit<FormItemProps, 'children'>

function Input(props: MergedProps) {
  // Desctruct Props
  const { placeholder } = props

  return (
    <FormItem
      {...props}
      normalize={(value: string) => (value ? (Number.isNaN(Number(value)) ? value : Number(value)) : value)}
    >
      <InputAntd allowClear placeholder={placeholder && translate(placeholder)} autoComplete="off" {...props} name="" />
    </FormItem>
  )
}

export default Input
