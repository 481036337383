import * as types from 'src/store/actions/storeTypes'

// import { notificationHeaderList } from 'src/store/actions/notifications/notificationHeaderList';
// import { api } from 'src/store/actions/api';
// import { SERVICES } from 'src/store/constants/serviceConstants';

// Başka bir servis ile çalışacak aksiyon burada tanımlanır.
// Bu aksiyon tetiklenir tetiklenmez reducer'a ilgili typelar gönderilir ve store update edilir.

export const setAddShortcutModalVisible = (value) => (dispatch) => {
  dispatch({
    type: `${types.ADD_SHORTCUT_MODAL_STATUS}_${value ? 'ACTIVE' : 'PASSIVE'}`,
    payload: value,
    requestName: types.ADD_SHORTCUT_MODAL_STATUS
  })
}
