import * as types from 'src/store/actions/storeTypes'

const initialState = {
  isAddShortcutModalOpened: false
}

export default function addShortcutModalReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SHORTCUT_MODAL_STATUS_PASSIVE:
      return {
        ...state,
        isAddShortcutModalOpened: false
      }
    case types.ADD_SHORTCUT_MODAL_STATUS_ACTIVE:
      return {
        ...state,
        isAddShortcutModalOpened: true
      }
    default:
      return state
  }
}
