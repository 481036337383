export enum STATUS {
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WHITE = 'white',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info',
  BRAND_1 = 'brand-1',
  BRAND_2 = 'brand-2',
  BRAND_3 = 'brand-3',
  DEFAULT = 'default',
  SECONDARY = 'secondary',
  LINK = 'link',
  LINK_PRIMARY = 'link primary',
  LINK_SUCCESS = 'link success',
  LINK_DANGER = 'link danger',
  LINK_WARNING = 'link warning',
  LINK_INFO = 'link info',
  LINK_WHITE = 'link white',
  LINK_BRAND_1 = 'link brand-1',
  LINK_BRAND_2 = 'link brand-2',
  LINK_BRAND_3 = 'link brand-3'
}
