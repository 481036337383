import { TYPES } from 'src/api/api-config/types'
import { useFetcher } from 'src/api/fetcher'

import { STATUS } from 'src/common/constants/status/status'

import Button from 'src/components/cta/button/Button'
import TranslatedText from 'src/components/translated-text/TranslatedText'

import notFoundImage from 'src/assets/img/err_404.svg'

function UserNotFound() {
  // Fetchs
  const logout = useFetcher(TYPES.AUTH_LOGOUT).action()

  // Actions
  const goBack = () => {
    sessionStorage.clear()
    logout.mutate({})
    sessionStorage.setItem('lastCrmPawderUrl', '/')
  }

  const onLogout = () => {
    localStorage.setItem('counter', `${0}`)
    localStorage.setItem('lastCrmPawderUrl', '/')
    goBack()
  }

  return (
    <div className="errorPageWrapper">
      <img src={notFoundImage} alt="404 Page Not Found" />
      <p className="margin_bottom_10">
        <TranslatedText label="GLOBAL.PAGES.USER_NOT_FOUND.DESCRIPTION" />
      </p>

      <Button
        name="back_to_home_cta"
        status={STATUS.PRIMARY}
        htmlType="submit"
        onClick={() => onLogout()}
        label="GLOBAL.FORM_ELEMENTS.CTA.BACK_TO_LOGIN"
      />
    </div>
  )
}

export default UserNotFound
