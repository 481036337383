import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { MENU, TMenuItem } from 'src/layouts/sidebar/menu'

import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'

import { checkPermission } from 'src/common/utils/checkPermissionUtils'
import { translate } from 'src/common/utils/translateUtil'

import Input from 'src/components/form-elements/input/Input'
import Icon from 'src/components/icon/Icon'

import SidebarMenuTrigger from './SidebarMenuTrigger'

interface HeaderLeftProps {
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>
  isSearchOpen: boolean
}

function HeaderLeft({ isSearchOpen, setIsSearchOpen }: HeaderLeftProps) {
  // State Variables
  const [searchResults, setSearchResults] = useState<object[]>([])
  const [showResults, setShowResults] = useState<boolean>(false)

  // Variables
  const navigate = useNavigate()

  const menuItemsHandler = () => {
    let items: TMenuItem[] = []
    MENU?.forEach((item: TMenuItem) => {
      if (checkPermission(item?.hasRight, item?.hasProfile)) {
        items = [...items, item]
        if (item?.children) {
          items = [...items, ...item.children]
        }
      }
    })
    return items
  }

  const menuItems = useMemo(menuItemsHandler, [])

  const handleSearch = (query: string) => {
    if (!query) {
      setSearchResults([])
      setShowResults(false)
      return
    }
    const filteredResults = menuItems?.filter(
      (result: TMenuItem) =>
        translate(result?.title)
          ?.toLowerCase()
          ?.includes(query?.toLowerCase())
    )

    setSearchResults(filteredResults)
    setShowResults(true)
  }

  const navigateSearched = (url) => {
    navigate(url)
    setSearchResults([])
    setShowResults(false)
  }

  // Actions
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest('#search-input-wrapper') &&
        !event.target.closest('#language-dropdown') &&
        !event.target.closest('.results-wrapper')
      ) {
        setShowResults(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
  }, [isSearchOpen, setIsSearchOpen])

  return (
    <div className="header-left">
      <SidebarMenuTrigger />
      <div id="search-input-wrapper">
        {/* Input for Searching */}
        {isSearchOpen && (
          <div className="input-wrapper">
            <Input
              name="header-search-input"
              className="header-search-input"
              bordered={false}
              prefix={<Icon icon={ICON_LIST.SEARCH} status={ICON_STATUS.SECONDARY} />}
              placeholder={translate('GLOBAL.FORM_ELEMENTS.CTA.SEARCH')}
              onChange={(event) => handleSearch(event.target.value)}
              notform
            />
            {MENU?.length > 0 && showResults && (
              <div className="results-wrapper">
                {searchResults?.map((item: any, index) => (
                  <div key={index} onClick={() => navigateSearched(item?.url)} className="result">
                    {translate(item?.title)}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderLeft
