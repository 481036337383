import { useEffect, useState } from 'react'

// import SockJsClient from 'react-stomp';
// import { Menu, Badge, Dropdown, Layout, Tabs, notification } from 'antd';
import { Layout } from 'antd'

// import { announcementHeaderList } from 'src/store/actions/announcement/announcementHeaderList';
// import { announcementReadUpdate } from 'src/store/actions/announcement/announcementReadUpdate';
// import { notificationHeaderList } from 'src/store/actions/notifications/notificationHeaderList';
// import { notificationHeaderRead } from 'src/store/actions/notifications/notificationHeaderRead';
// import epiasLogo from 'src/assets/img/epias-yatay.svg';
// import UserService from 'src/service/UserService';
import HeaderLeft from './_partials/HeaderLeft/HeaderLeft'
import HeaderRight from './_partials/HeaderRight/HeaderRight'

// Desctruct Components
const { Header } = Layout

function AppHeader() {
  // Store Variables

  const [width, setWidth] = useState(window.innerWidth)
  const [isSearchOpen, setIsSearchOpen] = useState(width > 936)

  // const authUserStates = useSelector((state: RootState) => state.authUser.success);

  // Notification List Clear Action
  // const clearNotifications = () => {
  //   dispatch(notificationHeaderRead());
  // };
  // Count Header Notification and Announcement

  // Announcements On Read Action
  // const onRead = (e, id) => {
  //   e?.target?.checked && dispatch(announcementReadUpdate([id]));
  // };

  // const onLogout = () => {
  //   UserService.doLogout();
  // };

  // Fetch Announcements List

  // dispatch(announcementHeaderList());
  // dispatch(notificationHeaderList());

  useEffect(() => {
    if (width < 936) {
      setIsSearchOpen(false)
    } else {
      setIsSearchOpen(true)
    }
  }, [width])

  // Update the window width and height
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  return (
    <Header className="appHeader">
      {/* <SockJsClient
        url={`${process?.env.REACT_APP_API_URL}${process?.env.REACT_APP_API_NOTIFIER_ADMIN_SERVICE}/notifier-application`}
        topics={[`/topic/notification/${null}`]}
        onConnect={() => {
          console.log('%c**************** NOTIFICATION Connected ****************', 'color:green');
        }}
        onDisconnect={() => {
          console.log('%c----- NOTIFICATION Disconnected ------', 'color:red');
        }}
        onMessage={(msg) => {
          dispatch(notificationHeaderList());

          notification.success({
            message: null,
            description: msg,
            placement: 'bottomLeft',
            key: 'push-notifications',
            duration: 5
          });
        }}
      /> */}
      {/* <SockJsClient
        url={`${process?.env.REACT_APP_API_URL}${process?.env.REACT_APP_API_NOTIFIER_ADMIN_SERVICE}/notifier-application`}
        topics={[`/topic/notification/announcement`]}
        onConnect={() => {
          console.log('%c**************** ANNOUNCEMENT Connected ****************', 'color:green');
        }}
        onDisconnect={() => {
          console.log('%c----- ANNOUNCEMENT Disconnected ------', 'color:red');
        }}
        onMessage={(msg) => {
          dispatch(announcementHeaderList());
          console.log('%c ANNOUNCEMENT SOCKET Message: ', 'color:blue', msg);
          notification.success({
            message: msg?.announcement?.subject,
            description: msg?.announcement?.description,
            placement: 'bottomLeft',
            key: `${msg?.announcement?.id}_push-announcements`,
            duration: 5
          });
        }}
      /> */}
      {/* <Link to="/" id="logo_link">
        <img src={epiasLogo} height="40px" alt="Epiaş Logo" />
      </Link> */}

      <HeaderLeft isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
      <HeaderRight isSearchOpen={isSearchOpen} setIsSearchOpen={setIsSearchOpen} />
    </Header>
  )
}

export default AppHeader
