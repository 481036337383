// Import Store
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import i18n from 'src/common/locales/i18n'
import { localLogout } from 'src/store/actions/auth/localLogout'
import { AppDispatch, RootState } from 'src/store/store'

import { TYPES } from 'src/api/api-config/types'
import { useFetcher } from 'src/api/fetcher'

import { CUSTOM_ICON_LIST } from 'src/common/constants/icon/customIconList'
import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { CUSTOM_ICON_SIZES } from 'src/common/constants/icon/iconSizes'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'

import { getTheme } from 'src/common/utils/getTheme'
import { translate } from 'src/common/utils/translateUtil'

// import { Badge, Dropdown } from "antd";
import Link from 'src/components/cta/link/Link'
import CustomIcon from 'src/components/icon/CustomIcon'
import Icon from 'src/components/icon/Icon'
import TranslatedText from 'src/components/translated-text/TranslatedText'

// import AnnouncementAndNotificationTabs from "../AnnouncementAndNotificationTabs";

type TUserDropdownMenuItem = {
  title?: string
  text?: string
  icon?: IconDefinition
  href?: string
  onClick?: () => void
  divider?: boolean
  badge?: JSX.Element
}

interface HeaderRightProps {
  setIsSearchOpen: Dispatch<SetStateAction<boolean>>
  isSearchOpen: boolean
}

function HeaderRight({ setIsSearchOpen, isSearchOpen }: HeaderRightProps): JSX.Element {
  // States
  const [isFullScreen, setIsFullScreen] = useState(false)
  /*   const [headerCounter] = useState(0);
  const [headerTabsVisible, setHeaderTabsVisible] = useState(false); */
  const [isLanguageDropdownVisible, setIsLanguageDropdownVisible] = useState(false)
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false)
  const [isDarkMode, setIsDarkMode] = useState(false)

  // Variables
  const dispatch: AppDispatch = useDispatch()
  const loggedUser = useSelector((state: RootState) => state?.authUser?.success?.data)

  const logoutCall = useFetcher(TYPES.AUTH_LOGOUT).action()
  const getThemeVal = localStorage.getItem('theme')
  const isDark = getThemeVal === 'dark'

  const onLogout = () => {
    // IF FAKE LOGIN
    if (process.env.REACT_APP_AUTHENTICATION === 'true') {
      sessionStorage.clear()
      dispatch(localLogout())
    } else {
      // IF SSO LOGIN
      sessionStorage.clear()
      logoutCall.mutateAsync({}).then(() => {
        dispatch(localLogout())
        sessionStorage.setItem('lastCrmPawderUrl', '/')
        window.location.href = String(process.env.REACT_APP_IDP_LOGOUT_URL)
      })
    }
  }

  const userDropdownMenu: TUserDropdownMenuItem[] = [
    {
      icon: ICON_LIST.LOGOUT,
      text: translate('GLOBAL.LAYOUT.HEADER.PROFILE.LOGOUT'),
      onClick: onLogout
    }
  ]

  // const handleHeaderCounter = () => {
  //   let counter = 0;
  //   const announcementCount = announcementHeaderListStates?.success?.data?.data?.length | 0;

  //   const notificationCount = notificationHeaderListStates?.length | 0;

  //   counter = announcementCount + notificationCount;

  //   setHeaderCounter(counter);
  // };

  const onChangeSwitch = (value) => {
    setIsDarkMode(value)
    localStorage.setItem('theme', value ? 'dark' : 'normal')
    getTheme()
  }

  const openFullscreen = () => {
    const elem = document.documentElement
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
      setIsFullScreen(true)
    }
  }
  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
      setIsFullScreen(false)
    }
  }

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      openFullscreen()
    } else {
      closeFullscreen()
    }
  }

  const toggleSearchBar = () => {
    setIsSearchOpen(!isSearchOpen)
  }

  window.onclick = (e) => {
    if (!e.target.closest('#user-dropdown') && !e.target.closest('#language-dropdown')) {
      setIsProfileDropdownVisible(false)
      setIsLanguageDropdownVisible(false)
      /*       setHeaderTabsVisible(false); */
    }
  }

  // Use Effects
  // useEffect(() => {
  //   handleHeaderCounter();
  // }, [announcementHeaderListStates, notificationHeaderListStates]);

  useEffect(() => {
    localStorage.setItem('language', i18n.language)
  }, [i18n.language])

  useEffect(() => {
    setIsDarkMode(isDark)
  }, [isDark])

  return (
    <div className="header-right">
      <div className="headerControls">
        {/* LANGUAGE CONTROLS */}
        <div
          id="language-dropdown"
          className="language-controls controller"
          onClick={() => setIsLanguageDropdownVisible((state) => !state)}
        >
          <CustomIcon
            name={i18n.language === 'tr' ? CUSTOM_ICON_LIST.TR : CUSTOM_ICON_LIST.EN}
            size={CUSTOM_ICON_SIZES.SMALL}
            key="set:1"
          />

          {isLanguageDropdownVisible && (
            <div className="language-controls-dropdown">
              <div className="dropdown-item" onClick={async () => i18n.changeLanguage('tr')}>
                <CustomIcon name={CUSTOM_ICON_LIST.TR} size={CUSTOM_ICON_SIZES.SMALL} />
                <TranslatedText label="GLOBAL.LAYOUT.HEADER.LANGUAGES.TR_LONG" />
              </div>
              <div className="dropdown-item" onClick={async () => i18n.changeLanguage('en')}>
                <CustomIcon name={CUSTOM_ICON_LIST.EN} size={CUSTOM_ICON_SIZES.SMALL} />
                <TranslatedText label="GLOBAL.LAYOUT.HEADER.LANGUAGES.EN_LONG" />
              </div>
            </div>
          )}
        </div>
        {/* SEARCH */}
        <div className="searchbar-trigger controller" onClick={toggleSearchBar}>
          <Icon
            name="dark-mode"
            icon={ICON_LIST.SEARCH}
            size="xl"
            status={isDarkMode ? ICON_STATUS.LIGHT_GREY : ICON_STATUS.DARK_GREY}
          />
        </div>
        {/* FULLSCREEN MODE */}
        <div
          className="fullscreen-mode-wrapper controller"
          onClick={() => {
            toggleFullScreen()
          }}
        >
          <Icon
            name="fullscreen-mode"
            icon={isFullScreen ? ICON_LIST.COMPRESS : ICON_LIST.EXPAND}
            size="xl"
            status={isDarkMode ? ICON_STATUS.LIGHT_GREY : ICON_STATUS.DARK_GREY}
            cursor="pointer"
          />
        </div>
        {/* DARK MODE */}{' '}
        <div
          className="dark-mode-wrapper controller"
          onClick={() => {
            onChangeSwitch(!isDarkMode)
          }}
        >
          {!isDarkMode ? (
            <Icon
              name="dark-mode"
              icon={ICON_LIST.MOON}
              size="xl"
              status={isDarkMode ? ICON_STATUS.WARNING : ICON_STATUS.DARK_GREY}
            />
          ) : (
            <CustomIcon
              className="sun-icon"
              name={CUSTOM_ICON_LIST.SUN}
              size={CUSTOM_ICON_SIZES.LARGE}
              status={ICON_STATUS.WARNING}
            />
          )}
        </div>
        {/*  NOTIFICATIONS */}
        {/*       <div
          className="notifications-drop-down-wrapper controller"
          id="notificationsDropdown_in_cta"
          onClick={() => {
            setHeaderTabsVisible(!headerTabsVisible);
          }}
        >
          <Dropdown
            overlay={<AnnouncementAndNotificationTabs />}
            trigger={["click"]}
          >
            <Badge count={headerCounter || 0} size="small">
              <Icon
                size="xl"
                name={"notifications"}
                icon={ICON_LIST.BELL}
                status={
                  isDarkMode ? ICON_STATUS.LIGHT_GREY : ICON_STATUS.DARK_GREY
                }
                cursor="pointer"
              />
            </Badge>
          </Dropdown>
        </div> */}
        {/* USER MENU */}
        <div
          id="user-dropdown"
          className="user-menu-trigger controller"
          onClick={() => setIsProfileDropdownVisible(!isProfileDropdownVisible)}
        >
          <div className="user-avatar">
            <Icon icon={ICON_LIST.USER_CIRCLE} status={ICON_STATUS.SECONDARY} size="2xl" />
          </div>
          <div className="avatar-details">
            <span className="fullname">{loggedUser?.user?.fullName}</span>
            <span className="job-title">{loggedUser?.user?.roleName}</span>
          </div>

          {isProfileDropdownVisible && (
            <div className="user-menu-dropdown">
              {/* Divider var ise */}
              {userDropdownMenu.map((option, index) => {
                if (option.title) {
                  return (
                    <div key={index} className="dropdown-item dropdown-title-item">
                      {translate(option.title)}
                    </div>
                  )
                }

                if (option.divider) {
                  return <div className="dropdown-divider" />
                }

                if (option?.href) {
                  return (
                    <div key={index} className="dropdown-item dropdown-link-item" onClick={option.onClick}>
                      {option?.text && option?.icon && (
                        <Link
                          name="dropdown-link-item"
                          label={option?.text}
                          url={option.href}
                          style={{ color: '#495057' }}
                          icon={
                            <Icon
                              icon={option?.icon}
                              size="1x"
                              status={ICON_STATUS.WHITE}
                              stroke="#495057"
                              strokeWidth={50}
                            />
                          }
                        />
                      )}
                    </div>
                  )
                }
                /* Diğer elementler */
                return (
                  <div key={index} className="dropdown-item" onClick={option.onClick}>
                    <div className="dropdown-item-wrapper">
                      {option.icon && (
                        <Icon
                          icon={option.icon}
                          size="1x"
                          status={ICON_STATUS.WHITE}
                          stroke="#495057"
                          strokeWidth={50}
                        />
                      )}

                      {option.badge}
                      {option.text}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default HeaderRight
