import { TYPES } from 'src/api/api-config/types'
import { useFetcher } from 'src/api/fetcher'

import { STATUS } from 'src/common/constants/status/status'

import Button from 'src/components/cta/button/Button'
import TranslatedText from 'src/components/translated-text/TranslatedText'

import notFoundImage from 'src/assets/img/err_404.svg'

function InvalidAuthorization() {
  // Fetchs
  const ssoLogout = useFetcher(TYPES.AUTH_LOGOUT).action()

  const goBack = () => {
    sessionStorage.clear()
    ssoLogout.mutate({})
    sessionStorage.setItem('lastCrmPawderUrl', '/')
  }

  return (
    <div className="errorPageWrapper">
      <img src={notFoundImage} alt="404 Page Not Found" />
      <p className="margin_bottom_10">
        <TranslatedText label="GLOBAL.PAGES.INVALID_AUTHORIZATION.DESCRIPTION" />
      </p>

      <Button
        name="back_to_logout_cta"
        status={STATUS.PRIMARY}
        htmlType="submit"
        onClick={goBack}
        label="GLOBAL.FORM_ELEMENTS.CTA.BACK_TO_LOGIN"
      />
    </div>
  )
}

export default InvalidAuthorization
