import { Input } from 'antd'
import { InputProps as InputPropsAntd } from 'antd/lib/input'

import FormItem, { FormItemProps } from 'src/components/form-elements/form-item/FormItem'

type MergedProps = InputPropsAntd & Omit<FormItemProps, 'children'>

function PasswordInput(props: MergedProps) {
  return (
    <FormItem {...props}>
      <Input.Password {...props} autoComplete="off" />
    </FormItem>
  )
}

export default PasswordInput
