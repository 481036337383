import { TEXT_TRASFORM } from 'src/common/constants/text/textTransform'

import TranslatedText from 'src/components/translated-text/TranslatedText'

import 'src/assets/styles/_title.scss'

export interface PageTitleProps {
  icon?: React.ReactElement
  textTransform?: TEXT_TRASFORM
  title: string
  subTitle?: string
  subTitleValue?: string | number | React.ReactElement
  titleValue?: string
  titleParams?: string[]
}

function PageTitle(props: PageTitleProps) {
  // Desctruct Props
  const { icon, textTransform, subTitle, subTitleValue, title, titleValue, titleParams } = props

  return (
    <div className="page-title">
      <h1 className="title">
        {icon}
        <TranslatedText label={title} textTransform={textTransform} />
        {titleValue && <span>{':  '}</span>}
        {titleValue && <TranslatedText label={titleValue} textTransform={textTransform} labelParams={titleParams} />}
      </h1>
      {(subTitle?.length || subTitleValue) && (
        <span className="sub-title">
          <TranslatedText label={subTitle} /> {subTitleValue}
        </span>
      )}
    </div>
  )
}

export default PageTitle
