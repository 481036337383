import { useDispatch, useSelector } from 'react-redux'

import { setSidebarStatus } from 'src/store/actions/sidebar/setSidebarStatus'
import { AppDispatch, RootState } from 'src/store/store'

function SidebarMenuTrigger() {
  // Store Variables
  const dispatch: AppDispatch = useDispatch()
  const isSidebarCollapsed = useSelector((state: RootState) => state.sidebarReducer?.isSidebarCollapsed)

  return (
    <div
      className={`sidebar-trigger-icon ${isSidebarCollapsed ? 'collapsed' : ' '}`}
      onClick={() => dispatch(setSidebarStatus(!isSidebarCollapsed))}
    >
      <div className="rectangle" />
    </div>
  )
}

export default SidebarMenuTrigger
