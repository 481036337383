import { FONT_SIZES } from 'src/common/constants/sizes/fontSizes'
import { STATUS } from 'src/common/constants/status/status'
import { TEXT_TRASFORM } from 'src/common/constants/text/textTransform'
import { TEXT_WEIGHT } from 'src/common/constants/text/textWeight'

// import { useTranslation } from 'react-i18next';
import { toLocaleCapitalize, toLocaleLowercase, toLocaleUppercase } from 'src/common/utils/stringUtil'
import { translate } from 'src/common/utils/translateUtil'

import 'src/assets/styles/_text.scss'

// Interface
export interface TranslatedTextProps {
  label?: string
  textTransform?: TEXT_TRASFORM
  status?: STATUS | undefined
  weight?: TEXT_WEIGHT | undefined
  italic?: boolean
  size?: FONT_SIZES | undefined
  onClick?: () => void
  labelParams?: string[]
  className?: string
}

function TranslatedText(props: TranslatedTextProps) {
  const { label, textTransform, status, weight, italic, size, onClick, labelParams, className } = props
  let text = label ? translate(label, labelParams) : ''

  text =
    textTransform === TEXT_TRASFORM.UPPERCASE
      ? toLocaleUppercase(text)
      : textTransform === TEXT_TRASFORM.LOWERCASE
        ? toLocaleLowercase(text)
        : textTransform === TEXT_TRASFORM.CAPITALIZE
          ? toLocaleCapitalize(text)
          : text

  return (
    <span
      className={`text-${status} font-${weight} ${size && size} ${italic && 'font-italic'} ${className}`}
      onClick={onClick}
    >
      {text}
    </span>
  )
}

export default TranslatedText

TranslatedText.defaultProps = {
  status: STATUS.DEFAULT,
  weight: 'regular',
  italic: false
}
