// Type Definition
interface ErrorCodeTypes {
  code: string
  title: string
  description: string | null
}

export const ERROR_CODES: ErrorCodeTypes[] = [
  {
    code: 'USER_NOT_FOUND',
    title: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOT_FOUND.MESSAGE',
    description: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOT_FOUND.DESCRIPTION'
  },
  {
    code: 'USER_NOT_AUTHORIZED',
    title: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOT_AUTHORIZED.MESSAGE',
    description: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.USER_NOT_AUTHORIZED.DESCRIPTION'
  },
  {
    code: 'INVALID_AUTHORIZATION',
    title: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_AUTHORIZATION.MESSAGE',
    description: 'GLOBAL.COMPONENTS.NOTIFICATIONS.ERROR_CODES.INVALID_AUTHORIZATION.DESCRIPTION'
  }
]
