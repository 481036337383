import { Helmet } from 'react-helmet'

import { getPageTitle } from 'src/common/utils/getPageTitle'
import { toLocaleCapitalize } from 'src/common/utils/stringUtil'
import { translate } from 'src/common/utils/translateUtil'

function PageHead() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{toLocaleCapitalize(translate(getPageTitle()))} | PAWDER</title>
      <meta name="description" content="pawder-crm" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
  )
}

export default PageHead
