export const RULE_MESSAGES = {
  DEFAULT: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED'
  },
  START_DATE: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.START_DATE.REQUIRED'
  },
  DESCRIPTION: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.DESCRIPTION.REQUIRED',
    MIN: 'GLOBAL.FORM_ELEMENTS.ERRORS.DESCRIPTION.MIN',
    MAX: 'GLOBAL.FORM_ELEMENTS.ERRORS.DESCRIPTION.MAX'
  },
  EMAIL: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.EMAIL',
    VALID: 'GLOBAL.FORM_ELEMENTS.ERRORS.EMAIL'
  },
  LINK: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.LINK',
    PATTERN: 'GLOBAL.FORM_ELEMENTS.ERRORS.LINK'
  },
  NEGATIVE_NUMBER: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    MAX: 'GLOBAL.FORM_ELEMENTS.ERRORS.NEGATIVE_NUMBER'
  },
  POSITIVE_NUMBER: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    MIN: 'GLOBAL.FORM_ELEMENTS.ERRORS.POSITIVE_NUMBER'
  },
  AMOUNT: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.AMOUNT.REQUIRED',
    MIN: 'GLOBAL.FORM_ELEMENTS.ERRORS.AMOUNT.MIN'
  },
  ALPHANUMERIC: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    VALID: 'GLOBAL.FORM_ELEMENTS.ERRORS.ALPHANUMERIC.VALID'
  },
  NUMBER: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    VALID: 'GLOBAL.FORM_ELEMENTS.ERRORS.NUMBER.VALID'
  },
  PATH: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.PATH.REQUIRED'
  },
  BIGGER_THAN_ZERO: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    VALID: 'GLOBAL.FORM_ELEMENTS.ERRORS.BIGGER_THAN_ZERO'
  },
  PRICE: {
    REQUIRED: 'GLOBAL.FORM_ELEMENTS.ERRORS.REQUIRED',
    VALID: 'GLOBAL.FORM_ELEMENTS.ERRORS.PRICE.VALID'
  }
}
