import { ReactElement, Ref } from 'react'

import { Form as FormAntd } from 'antd'
import { FormInstance, FormProps as FormPropsAntd } from 'antd/lib/form'

import { FORM_LAYOUT } from 'src/common/constants/form/formLayout'

export interface FormProps extends FormPropsAntd {
  children: ReactElement | ReactElement[]
  name: string
  layout?: FORM_LAYOUT
  ref?: Ref<FormInstance<any>> | undefined
}

function FormApp(props: FormProps) {
  // Desctruct props
  const { children } = props

  return <FormAntd {...props}>{children}</FormAntd>
}

FormApp.defaultProps = {
  layout: FORM_LAYOUT.VERTICAL
}

export default FormApp
