import { useDispatch, useSelector } from 'react-redux'

import { Col, Form, Row } from 'antd'
import { setAddShortcutModalVisible } from 'src/store/actions/sidebar/setAddShortcutModalVisible'
import { RootState } from 'src/store/store'

import { FORM_RULES } from 'src/common/constants/form/formRules'
import { FONT_SIZES } from 'src/common/constants/sizes/fontSizes'
import { STATUS } from 'src/common/constants/status/status'

import Button from 'src/components/cta/button/Button'
import FormApp from 'src/components/form-elements/form/FormApp'
import Input from 'src/components/form-elements/input/Input'
import Select from 'src/components/form-elements/select/Select'
import Icon from 'src/components/icon/Icon'
import Modal from 'src/components/modal/Modal'
import Popconfirm from 'src/components/pop-confirm/Popconfirm'
import TranslatedText from 'src/components/translated-text/TranslatedText'

function ShortcutBarForm(props: any) {
  // Destruct Props
  const {
    mode,
    shortcuts,
    editShortcutModalVisible,
    selectedShortcut,
    setShortcuts,
    setShortcutCounter,
    picked,
    setPicked,
    setEditShortcutModalVisible,
    setDisplayColorPicker,
    icons,
    shortcutCounter
  } = props

  const [form] = Form.useForm()

  // Store Variables
  const dispatch: any = useDispatch()
  const isAddShortcutModalOpened = useSelector(
    (state: RootState) => state.addShortcutModalReducer?.isAddShortcutModalOpened
  )

  // Functions
  const closeModal = () => {
    setPicked({
      color: 'transparent',
      textColor: 'transparent',
      url: '',
      icon: undefined
    })
    setDisplayColorPicker(false)
    dispatch(setAddShortcutModalVisible(false))
    setEditShortcutModalVisible(false)
  }

  const addShortcut = () => {
    if (picked.url) {
      let lastShortcut
      if (shortcuts.length < 1) {
        lastShortcut = { id: 0 }
      } else {
        lastShortcut = shortcuts[shortcuts.length - 1]
      }

      const newShortcut = {
        id: lastShortcut.id + 1,
        color: picked.color,
        textColor: picked.textColor,
        url: picked.url,
        icon: picked.icon ? <Icon icon={picked.icon?.props?.icon} style={{ color: picked.textColor }} /> : undefined
      }

      const updatedShortcuts = [...shortcuts, newShortcut]
      setShortcuts(updatedShortcuts)
      localStorage.setItem('shortcuts', JSON.stringify(updatedShortcuts))
      setShortcutCounter(shortcutCounter + 1)
      closeModal()
    }
  }

  // For editing a shortcut
  const editShortcut = () => {
    const updatedShortcuts = shortcuts.map((sc) => {
      const scItem = sc
      if (scItem.id === selectedShortcut?.id) {
        scItem.textColor = picked.textColor
        scItem.color = picked.color
        scItem.url = picked.url

        if (picked.icon) {
          scItem.icon = <Icon icon={picked.icon.props.icon} style={{ color: picked.textColor }} />
        }

        if (picked.icon === undefined) {
          scItem.icon = undefined
        }
      }
      return scItem
    })
    setShortcuts(updatedShortcuts)
    localStorage.setItem('shortcuts', JSON.stringify(updatedShortcuts))
    closeModal()
  }

  const removeShortcut = (id: number) => {
    const updatedShortcuts = shortcuts.filter((sc) => sc.id !== id)
    setShortcuts(updatedShortcuts)
    localStorage.setItem('shortcuts', JSON.stringify(updatedShortcuts))
    closeModal()
    setShortcutCounter(shortcutCounter - 1)
  }

  const onFinish = () => {
    if (mode === 'add') {
      addShortcut()
    } else {
      editShortcut()
    }
  }

  return (
    <Modal
      open={mode === 'add' ? isAddShortcutModalOpened : editShortcutModalVisible}
      width={500}
      className="shortcut-modal-wrapper"
      footer={null}
      onCancel={closeModal}
    >
      <div id="shortcut-modal">
        <Row justify="center">
          <Col>
            <TranslatedText
              label={
                mode === 'add'
                  ? 'GLOBAL.COMPONENTS.MODAL.TITLES.ADD_SHORTCUT'
                  : 'GLOBAL.COMPONENTS.MODAL.TITLES.EDIT_SHORTCUT'
              }
              size={FONT_SIZES.XXL}
            />
          </Col>
        </Row>
        <div className="shortcut-preferences">
          <FormApp name="shortcut-bar-form" className="shortcut-bar-form" form={form} onFinish={onFinish}>
            <>
              <Row className="icon-and-url" justify="center" align="middle" gutter={12}>
                <Col span={6}>
                  <Select
                    label="GLOBAL.FORM_ELEMENTS.LABELS.ICON"
                    name="shortcut-icon-selector"
                    id="shortcut-icon-selector"
                    options={icons}
                    initialValue={mode === 'add' ? 0 : null}
                    onChange={(value) => {
                      const selected = icons.filter((icon) => icon.value === value)[0]

                      setPicked({
                        ...picked,
                        icon:
                          value === 0 ? undefined : (
                            <Icon icon={selected.label.props.icon} style={{ color: picked.textColor }} size="sm" />
                          )
                      })
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    label="URL"
                    onChange={(e) => setPicked({ ...picked, url: e.target.value })}
                    name="shortcutUrlSelector"
                    className="shortcut-url-selector"
                    defaultValue={picked.url}
                    value={picked.url}
                    rules={FORM_RULES.PATH.BASIC}
                  />
                </Col>
                <Col span={2}>
                  <Input
                    label="GLOBAL.FORM_ELEMENTS.LABELS.BOX"
                    name="boxColorPicker"
                    className="box-color-picker"
                    type="color"
                    onChange={(e) => {
                      setPicked({
                        ...picked,
                        color: e.target.value
                      })
                    }}
                    style={{ backgroundColor: picked.color }}
                  />
                </Col>
                <Col span={2}>
                  <Input
                    label="GLOBAL.FORM_ELEMENTS.LABELS.LETTER"
                    name="letterColorPicker"
                    className="letter-color-picker"
                    type="color"
                    onChange={(e) => {
                      setPicked({ ...picked, textColor: e.target.value })
                    }}
                    style={{ backgroundColor: picked.textColor }}
                  />
                </Col>
              </Row>

              <Row className="shortcut-modal-footer" justify="center" align="bottom">
                <Col span={4}>
                  <Row>
                    <Col>
                      <TranslatedText label="GLOBAL.FORM_ELEMENTS.LABELS.PREVIEW" />
                    </Col>
                  </Row>
                  <Row>
                    {' '}
                    <div
                      className="shortcut-box"
                      style={{
                        backgroundColor: picked.color ? picked.color : selectedShortcut?.color,
                        color: picked.textColor ? picked.textColor : selectedShortcut?.textColor
                      }}
                    >
                      {selectedShortcut?.icon ? (
                        <Icon
                          icon={selectedShortcut?.icon?.props?.icon}
                          style={{
                            color: picked.textColor ? picked.textColor : selectedShortcut?.textColor
                          }}
                        />
                      ) : picked.icon ? (
                        <Icon
                          icon={picked.icon?.props?.icon}
                          style={{
                            color: picked.textColor ? picked.textColor : selectedShortcut?.textColor
                          }}
                        />
                      ) : picked.url ? (
                        picked.url[1]?.toLocaleUpperCase()
                      ) : (
                        selectedShortcut?.url[1]?.toLocaleUpperCase()
                      )}
                    </div>
                  </Row>
                </Col>
                <Col span={18}>
                  <Row align="middle" justify="end" gutter={10}>
                    {mode === 'edit' && (
                      <Popconfirm
                        confirmTitle="GLOBAL.COMPONENTS.POPCONFIRM.SHORTCUT_REMOVE"
                        confirmText="GLOBAL.FORM_ELEMENTS.CTA.REMOVE"
                        onConfirm={() => {
                          removeShortcut(selectedShortcut?.id as number)
                        }}
                      >
                        <Col>
                          <Button
                            name="remove"
                            key="remove"
                            marginright={10}
                            label="GLOBAL.FORM_ELEMENTS.CTA.DELETE"
                            status={STATUS.DANGER}
                          />
                        </Col>
                      </Popconfirm>
                    )}
                    <Col>
                      <Button
                        name="cancel"
                        key="cancel"
                        status={STATUS.DEFAULT}
                        label="GLOBAL.FORM_ELEMENTS.CTA.CANCEL"
                        onClick={() => {
                          closeModal()
                        }}
                      />
                    </Col>
                    <Col>
                      <Button
                        name="submit"
                        key="submit"
                        status={STATUS.SUCCESS}
                        label="GLOBAL.FORM_ELEMENTS.CTA.SAVE"
                        htmlType="submit"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          </FormApp>
        </div>
      </div>
    </Modal>
  )
}

export default ShortcutBarForm
