import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes'
import { STATUS } from 'src/common/constants/status/status'
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants'

import Button from 'src/components/cta/button/Button'
import Modal from 'src/components/modal/Modal'
import TranslatedText from 'src/components/translated-text/TranslatedText'

interface LogoutCatchModalProps {
  open: boolean
  setVisible: (value: boolean) => void
}

function LogoutCatchModal(props: LogoutCatchModalProps) {
  // Desctruct Props
  const { open, setVisible } = props

  // Store Variables
  // Functions
  const handleSuccess = () => {
    setVisible(false)
    localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`)
  }
  const handleClose = () => {
    setVisible(false)
    localStorage.setItem('counter', `${0}`)
    window.location.href = `${process.env.REACT_APP_CAS_LOGOUT_URL}`
  }

  return (
    <Modal
      title="GLOBAL.COMPONENTS.MODAL.TITLES.LOGOUT_CATCH"
      wrapClassName="logout_catch"
      open={open}
      closable={false}
      footer={[
        <Button
          name="cancel_cta"
          key="cancel"
          label="GLOBAL.FORM_ELEMENTS.CTA.LOGOUT"
          status={STATUS.DANGER}
          sizes={BUTTON_SIZES.MD}
          onClick={() => handleClose()}
          marginright={10}
        />,
        <Button
          name="success_cta"
          key="success"
          label="GLOBAL.FORM_ELEMENTS.CTA.STAY_LOGIN"
          status={STATUS.SUCCESS}
          sizes={BUTTON_SIZES.MD}
          onClick={() => handleSuccess()}
        />
      ]}
    >
      <p>
        <TranslatedText label="GLOBAL.PAGES.LAYOUT.LOGOUT_CATCH_MODAL.DESCRIPTION" />
      </p>
    </Modal>
  )
}

export default LogoutCatchModal
