// Import Rule Messages
import { RULE_MESSAGES } from 'src/common/constants/form/ruleMessages'
// Import Utils
import {
  alphaNumericRegex,
  alphaNumericWithSlashRegex,
  emailRegex,
  phoneRegex,
  websiteRegex
} from 'src/common/constants/regex/regex'

export const FORM_RULES = {
  DEFAULT: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.DEFAULT.REQUIRED,
        required: true
      }
    ]
  },
  START_DATE: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.START_DATE.REQUIRED,
        required: true
      }
    ]
  },
  DESCRIPTION: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.DESCRIPTION.REQUIRED,
        required: true
      },
      {
        min: 10,
        message: RULE_MESSAGES.DESCRIPTION.MIN
      },
      {
        max: 255,
        message: RULE_MESSAGES.DESCRIPTION.MAX
      }
    ],
    BASIC: [
      {
        min: 3,
        message: RULE_MESSAGES.DESCRIPTION.MIN
      },
      {
        max: 1000,
        message: RULE_MESSAGES.DESCRIPTION.MAX
      }
    ]
  },
  LINK: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.LINK.REQUIRED,
        required: true,
        pattern: websiteRegex
      }
    ],
    BASIC: [
      {
        message: RULE_MESSAGES.LINK.REQUIRED,
        pattern: websiteRegex
      }
    ]
  },
  EMAIL: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.EMAIL.REQUIRED,
        required: true,
        pattern: emailRegex
      }
    ],
    BASIC: [
      {
        message: RULE_MESSAGES.EMAIL.VALID,
        pattern: emailRegex,
        required: false
      }
    ]
  },
  NEGATIVE_NUMBER: {
    REQUIRED: [
      {
        required: true,
        type: 'number',
        message: RULE_MESSAGES.NEGATIVE_NUMBER.REQUIRED
      },
      {
        max: 0,
        type: 'number',
        message: RULE_MESSAGES.NEGATIVE_NUMBER.MAX
      }
    ],
    BASIC: [
      {
        max: 0,
        type: 'number',
        message: RULE_MESSAGES.NEGATIVE_NUMBER.REQUIRED
      }
    ]
  },
  POSITIVE_NUMBER: {
    REQUIRED: [
      {
        required: true,
        type: 'number',
        message: RULE_MESSAGES.POSITIVE_NUMBER.REQUIRED
      },
      {
        min: 1,
        type: 'number',
        message: RULE_MESSAGES.POSITIVE_NUMBER.MIN
      }
    ],
    BASIC: [
      {
        min: 0,
        type: 'number',
        message: RULE_MESSAGES.POSITIVE_NUMBER.REQUIRED
      }
    ]
  },
  BIGGER_THAN_ZERO: {
    REQUIRED: [
      {
        required: true,
        type: 'number',
        message: RULE_MESSAGES.BIGGER_THAN_ZERO.REQUIRED
      },
      {
        min: 1,
        type: 'number',
        message: RULE_MESSAGES.BIGGER_THAN_ZERO.VALID
      }
    ],
    BASIC: [
      {
        min: 1,
        type: 'number',
        message: RULE_MESSAGES.BIGGER_THAN_ZERO.VALID
      }
    ]
  },
  PRICE: {
    REQUIRED: [
      {
        required: true,
        type: 'number',
        message: RULE_MESSAGES.PRICE.REQUIRED
      },
      {
        min: 1,
        type: 'number',
        message: RULE_MESSAGES.PRICE.VALID
      }
    ],
    BASIC: [
      {
        min: 1,
        type: 'number',
        message: RULE_MESSAGES.PRICE.VALID
      }
    ]
  },
  AMOUNT: {
    REQUIRED: [
      {
        required: true,
        type: 'number',
        message: RULE_MESSAGES.AMOUNT.REQUIRED
      },
      {
        min: 0,
        type: 'number',
        message: RULE_MESSAGES.AMOUNT.MIN
      }
    ],
    BASIC: [
      {
        min: 0,
        type: 'number',
        message: RULE_MESSAGES.AMOUNT.MIN
      }
    ]
  },
  ALPHANUMERIC: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.ALPHANUMERIC.REQUIRED,
        required: true,
        pattern: alphaNumericRegex
      }
    ],
    BASIC: [
      {
        message: RULE_MESSAGES.ALPHANUMERIC.VALID,
        pattern: alphaNumericRegex,
        required: false
      }
    ]
  },
  PHONE_NUMBER: {
    BASIC: [
      {
        message: RULE_MESSAGES.NUMBER.VALID,
        pattern: phoneRegex,
        required: true
      }
    ]
  },
  PATH: {
    REQUIRED: [
      {
        message: RULE_MESSAGES.PATH.REQUIRED,
        required: true,
        pattern: alphaNumericWithSlashRegex
      }
    ],
    BASIC: [
      {
        message: RULE_MESSAGES.PATH.REQUIRED,
        required: false,
        pattern: alphaNumericWithSlashRegex
      }
    ]
  }
}
