// Import React Query
import { QueryCache, QueryClient } from '@tanstack/react-query'

// Minute
const MINUTE = 1000 * 60

// QueryClient
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * MINUTE
    }
  },
  queryCache: new QueryCache({})
})
