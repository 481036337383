export const GLOBAL_LOADING_ACTIVE = 'GLOBAL_LOADING_ACTIVE'
export const GLOBAL_LOADING_PASSIVE = 'GLOBAL_LOADING_PASSIVE'

export const SIDEBAR_STATUS = 'SIDEBAR_STATUS'
export const SIDEBAR_STATUS_ACTIVE = 'SIDEBAR_STATUS_ACTIVE'
export const SIDEBAR_STATUS_PASSIVE = 'SIDEBAR_STATUS_PASSIVE'

export const ADD_SHORTCUT_MODAL_STATUS = 'ADD_SHORTCUT_MODAL_STATUS'
export const ADD_SHORTCUT_MODAL_STATUS_ACTIVE = 'ADD_SHORTCUT_MODAL_STATUS_ACTIVE'
export const ADD_SHORTCUT_MODAL_STATUS_PASSIVE = 'ADD_SHORTCUT_MODAL_STATUS_PASSIVE'

// Response Error
export const TRIGGER_ERROR_MODAL = 'TRIGGER_ERROR_MODAL'

export const GLOBAL_DARKMODE_STATUS = 'GLOBAL_DARKMODE_STATUS'
