import { notification } from 'antd'

import { translate } from 'src/common/utils/translateUtil'

// Types
export type NotificationStatus = 'success' | 'info' | 'warning' | 'error'

export const notificationUtil = (title, description?, type?: NotificationStatus) => {
  notification?.[type || 'info']({
    message: translate(title),
    description: translate(description),
    placement: 'bottomLeft',
    duration: 5
  })
}
