import React from 'react'

import { Tooltip as TooltipAntd } from 'antd'
import { TooltipProps as TooltipPropsAntd } from 'antd/lib/tooltip'

import TranslatedText from 'src/components/translated-text/TranslatedText'

export interface TooltipProps {
  title?: string | undefined
  isCustomTitle?: boolean
  children: React.ReactElement
}

type MergedTooltipProps = TooltipPropsAntd & TooltipProps

function Tooltip(props: MergedTooltipProps) {
  // Desctruct Props
  const { children, title, isCustomTitle } = props

  return (
    <TooltipAntd {...props} title={!isCustomTitle ? <TranslatedText label={title} /> : title}>
      {children}
    </TooltipAntd>
  )
}

export default Tooltip
