import { TYPES } from 'src/api/api-config/types'
import { useFetcher } from 'src/api/fetcher'

import { FORM_RULES } from 'src/common/constants/form/formRules'
import { BUTTON_SIZES } from 'src/common/constants/sizes/buttonSizes'
import { STATUS } from 'src/common/constants/status/status'

import Button from 'src/components/cta/button/Button'
import FormApp from 'src/components/form-elements/form/FormApp'
import Input from 'src/components/form-elements/input/Input'
import PasswordInput from 'src/components/form-elements/input/PasswordInput'

import logo from 'src/assets/img/pawder-logo.svg'
import 'src/assets/styles/_login.scss'

function LoginForm() {
  // Destruct Props

  // Fetchers

  const loginUser = useFetcher(TYPES.AUTH_LOGIN).action()

  // Actions
  const loginHandler = (values) => {
    loginUser.mutate(values)

    // store.dispatch({
    //   type: `${USER_AUTH_REDUCER}_SUCCESS`,
    //   payload: {
    //     data: {
    //       token:
    //         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiZmFydWsuYWxrYW4iLCJqdGkiOiIyMjNlZmRlMS1kMGE4LTQzNjItOWE5Zi1lMWE2NzY2NzBmZWUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJTdXBlckFkbWluIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZWlkZW50aWZpZXIiOiI1NiIsIk9yZ0lkIjoiIiwiZXhwIjoxNzAwMTQ3MDkxLCJpc3MiOiJhcG1zLmlkMy5jb20iLCJhdWQiOiJhcG1zLmlkMy5jb20ifQ.oUngefd-8TmJLOMSEc53XmMbA-RyOrTW07FT0g28G4g',
    //       user: {
    //         currentUserId: 56,
    //         fullName: 'CANER AŞA',
    //         userName: 'caner.asa',
    //         email: 'caner.asa@id3.com.tr',
    //         roleName: 'SuperAdmin'
    //       },
    //       expireTime: '2023-11-16T21:34:51Z',
    //       rightList: [
    //         'User.Management.Menu',
    //         'System.Parameters.Menu',
    //         'User.Management.Page',
    //         'User.Management.Super.Admin.Definition.Tab',
    //         'User.Management.Super.Admin.Definiton.Write',
    //         'User.Management.Super.Admin.Definiton.Delete',
    //         'User.Management.Super.Admin.Definition.Read',
    //         'User.Management.Organizational.Admin.Definition.Tab',
    //         'User.Management.Organizational.Admin.Definition.Write',
    //         'User.Management.Organizational.Admin.Definition.Delete',
    //         'User.Management.Organizational.Admin.Definition.Read',
    //         'User.Management.User.Definition.Tab',
    //         'User.Management.User.Definition.Write',
    //         'User.Management.User.Definition.Delete',
    //         'User.Management.User.Definition.Read',
    //         'Organization.Management.Menu',
    //         'Organization.Management.Page',
    //         'Organization.Management.Write',
    //         'Organization.Management.Delete',
    //         'Organization.Management.Read',
    //         'Resource.Type.Page',
    //         'Resource.Type.Write',
    //         'Resource.Type.Delete',
    //         'Resource.Type.Read',
    //         'Qualification.Type.Page',
    //         'Qualification.Type.Write',
    //         'Qualification.Type.Delete',
    //         'Qualification.Type.Read',
    //         'Record.Type.Page',
    //         'Record.Type.Write',
    //         'Record.Type.Delete',
    //         'Record.Type.Read',
    //         'Notification.Page',
    //         'Notification.Delete',
    //         'Notification.Write',
    //         'Notification.Read',
    //         'DataSource.Page',
    //         'DataSource.Write',
    //         'DataSource.Delete',
    //         'DataSource.Read',
    //         'Organiaztion.Details.Page',
    //         'Organiaztion.Details.Write',
    //         'Organiaztion.Details.Delete',
    //         'Organiaztion.Details.Read',
    //         'Resource.Management.Menu',
    //         'Resource.Management.Page',
    //         'Resource.Management.Write',
    //         'Resource.Management.Delete',
    //         'Resource.Management.Read',
    //         'Resource.Details.Page',
    //         'Resource.Details.Write',
    //         'Resource.Details.Delete',
    //         'Resource.Details.Read',
    //         'Qualification.Management.Menu',
    //         'Qualification.Management.Page',
    //         'Qualification.Management.Write',
    //         'Qualification.Management.Delete',
    //         'Qualification.Management.Read',
    //         'Qualification.History.Read'
    //       ]
    //     }
    //   }
    // })
  }

  return (
    <div className="login-wrapper">
      <div className="login-container">
        <div className="logo-login">
          <img src={logo} alt="pawder" />
        </div>

        <FormApp name="loginForm" onFinish={loginHandler} initialValues={{ email: '', password: '' }}>
          <>
            {/* Email */}
            <Input name="email" label="GLOBAL.FORM_ELEMENTS.LABELS.EMAIL" rules={FORM_RULES.EMAIL.REQUIRED} />

            {/* Password */}
            <PasswordInput
              label="GLOBAL.FORM_ELEMENTS.LABELS.PASSWORD"
              name="password"
              rules={FORM_RULES.DEFAULT.REQUIRED}
            />

            {/* CTA */}
            <Button
              name="login"
              status={STATUS.PRIMARY}
              label="GLOBAL.FORM_ELEMENTS.CTA.LOGIN"
              block
              htmlType="submit"
              sizes={BUTTON_SIZES.MD}
            />
          </>
        </FormApp>
      </div>
      <p className="footer-text">CRM for Pawder</p>
    </div>
  )
}

export default LoginForm
