import { useEffect, useState } from 'react'

import { Select as SelectAntd } from 'antd'
import { SelectProps as SelectPropsAntd } from 'antd/lib/select'

import { isArray } from 'src/common/utils/arrayUtils'
import { removeAccents } from 'src/common/utils/stringUtil'
import { translate } from 'src/common/utils/translateUtil'

import FormItem, { FormItemProps } from 'src/components/form-elements/form-item/FormItem'

// Interface
export interface SelectProps extends SelectPropsAntd<any> {
  data?: Array<Record<string, any>>
  displaykey?: string
  valuekey?: string
  customkey?: string | undefined
  all?: boolean
  notAllowClear?: boolean
  placeholder?: string
  multipleParams?
  inputRef?
  isTranslated?: boolean
  doubleDisplayKey?: string[]
  isGroup?: boolean
  groupKey?: string | undefined
  hideParentAll?: boolean
  autoComplete?: boolean
}

export type MergedSelectProps = SelectProps & Omit<FormItemProps, 'children'>

// Import Child Components
const { Option, OptGroup } = SelectAntd

function Select(props: MergedSelectProps) {
  // Desctruct Props
  const {
    data,
    displaykey,
    valuekey,
    customkey,
    placeholder,
    mode,
    all,
    notAllowClear,
    name,
    onChange,
    multipleParams,
    onClear,
    inputRef,
    isTranslated,
    isGroup,
    groupKey,
    hideParentAll,
    autoComplete,
    doubleDisplayKey
  } = props

  const [selectAll, setSelectAll] = useState(false)

  const handleSelectClear = () => {
    setSelectAll(false)
  }

  const handleSelectAll = (value) => {
    const isArrayResult = isArray(value)
    const formName = name

    if (isArrayResult) {
      if (value?.includes('all')) {
        multipleParams?.form?.setFieldsValue({
          [`${formName}`]: multipleParams?.data?.map((item) => item?.[`${multipleParams?.valueKey}`])
        })
        setSelectAll(true)
      } else if (value?.includes('clear')) {
        multipleParams.form.resetFields([`${formName}`])
        setSelectAll(false)
      } else if (value?.length === data?.length) {
        setSelectAll(true)
      } else {
        setSelectAll(false)
      }
    }
  }

  useEffect(() => {
    if (!multipleParams?.form?.getFieldValue(name)) {
      setSelectAll(false)
    }
  }, [multipleParams?.form?.getFieldValue(name)])

  return (
    <FormItem {...props}>
      <SelectAntd
        {...props}
        ref={inputRef}
        defaultActiveFirstOption
        getPopupContainer={(trigger: any) => trigger?.parentNode} // Scroll Fixed problemi çözümü
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterOption={
          !autoComplete
            ? false
            : (input, option: any) => {
                let chlds

                if (isGroup) {
                  chlds = option?.children?.props?.children
                } else {
                  chlds = option?.children
                }
                return chlds && removeAccents(chlds)?.indexOf?.(removeAccents(input)) >= 0
              }
        }
        optionFilterProp="children"
        showSearch
        onClear={
          mode === 'multiple'
            ? () => {
                handleSelectClear()
                onClear != null && onClear()
              }
            : onClear
        }
        className="w-100"
        onChange={
          mode === 'multiple'
            ? (value, option) => {
                handleSelectAll(value)
                onChange != null && onChange(value, option)
              }
            : onChange
        }
        allowClear={!notAllowClear}
        placeholder={
          placeholder ? translate(placeholder.toString()) : translate('GLOBAL.FORM_ELEMENTS.PLACEHOLDERS.SELECT')
        }
      >
        {!isGroup && all && <Option value="">{translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')}</Option>}
        {!isGroup && mode === 'multiple' && all && data != null && data?.length > 1 && (
          <Option value={selectAll ? 'clear' : 'all'}>
            {selectAll
              ? translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.CLEAR')
              : translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL_SELECT')}
          </Option>
        )}

        {/* IS NOT GROUP */}
        {!isGroup &&
          data?.length &&
          data?.map((option) => (
            <Option
              value={valuekey ? option?.[valuekey] : option?.id ? option?.id : `${option}`}
              custom={customkey && option?.[customkey] ? option?.[customkey] : ''}
              key={valuekey ? option?.[valuekey] : option.id ? option?.id : `${option}`}
            >
              {isTranslated && displaykey
                ? translate(`GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.${option?.[displaykey]}`)
                : displaykey
                  ? doubleDisplayKey != null
                    ? `${option?.[doubleDisplayKey[0]]} ${option?.[doubleDisplayKey[1]]}`
                    : option?.[displaykey]
                  : option?.name}
            </Option>
          ))}

        {/* IS GROUP */}

        {isGroup &&
          data?.length &&
          data?.map((option) => (
            <OptGroup
              label={
                isTranslated && groupKey
                  ? translate(`GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.${option?.[groupKey]}`)
                  : groupKey
                    ? option?.[groupKey]
                    : option?.name
              }
            >
              {Array.isArray(option?.items) &&
                option?.items?.map((itemOption, index) => (
                  <Option
                    title={
                      isTranslated && displaykey
                        ? translate(`GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.${itemOption?.[displaykey]}`)
                        : displaykey && !itemOption?.isParent
                          ? `${translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')} ${itemOption?.[displaykey]}`
                          : displaykey
                            ? itemOption?.[displaykey]
                            : !hideParentAll && itemOption?.isParent
                              ? `${translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')} ${itemOption?.name}`
                              : itemOption?.name
                    }
                    value={valuekey ? itemOption?.[valuekey] : itemOption?.id ? itemOption?.id : `${itemOption}`}
                    custom={customkey && itemOption?.[customkey] ? itemOption?.[customkey] : ''}
                    style={!itemOption?.isParent ? { paddingLeft: 30 } : null}
                    key={
                      valuekey
                        ? `${itemOption?.[valuekey].toString()}${index}`
                        : itemOption?.id.toString()
                          ? itemOption?.id.toString()
                          : `${itemOption.toString()}`
                    }
                  >
                    <div>
                      {isTranslated && displaykey
                        ? translate(`GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.${itemOption?.[displaykey]}`)
                        : displaykey && !itemOption?.isParent
                          ? `${translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')} ${itemOption?.[displaykey]}`
                          : displaykey
                            ? itemOption?.[displaykey]
                            : !hideParentAll && itemOption?.isParent
                              ? `${translate('GLOBAL.FORM_ELEMENTS.SELECT.OPTIONS.ALL')} ${itemOption?.name}`
                              : itemOption?.name}
                    </div>
                  </Option>
                ))}
            </OptGroup>
          ))}
      </SelectAntd>
    </FormItem>
  )
}

export default Select

Select.defaultProps = {
  autoComplete: true
}
