import { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'

import { Layout } from 'antd'
import LogoutCatchModal from 'src/layouts/_partials/LogoutCatchModal'
import AppHeader from 'src/layouts/header/AppHeader'
import Sidebar from 'src/layouts/sidebar/Sidebar'
// import { setAddShortcutModalVisible } from 'src/store/actions/sidebar/setAddShortcutModalVisible'
// import { AppDispatch, RootState } from 'src/store/store'
import { RootState } from 'src/store/store'

import { LOADING_TYPES } from 'src/common/constants/loading/loadingTypes'
import { SESSION_TIMEOUT_MIN } from 'src/common/constants/user/authConstants'

import { getPageTitle } from 'src/common/utils/getPageTitle'

// import SpeedDial from 'src/components/cta/button/SpeedDial'
// import Icon from 'src/components/icon/Icon'
import Loading from 'src/components/loading/Loading'
import PageHead from 'src/components/page-head/PageHead'
import PageTitle from 'src/components/title/page-title/PageTitle'

import 'src/assets/styles/_layout.scss'

// Desctruct Components
const { Content } = Layout

function DefaultLayout() {
  // Store Variables
  const globalLoadingStates = useSelector((state: RootState) => state?.globalLoading)
  // const isSidebarCollapsed = useSelector((state: RootState) => state.sidebarReducer?.isSidebarCollapsed)
  // const isAddShortcutModalOpened = useSelector(
  //   (state: RootState) => state.addShortcutModalReducer?.isAddShortcutModalOpened
  // )

  // Dispatch
  // const dispatch: AppDispatch = useDispatch()

  // Variables
  const [visibleLogoutModal, setVisibleLogoutModal] = useState(false)
  // const [shortcuts, setShortcuts] = useState<any>([])
  const pageTitle = getPageTitle()
  const location = useLocation()

  // On Route Change Detect
  useEffect(() => {
    // runs on location, route change
    localStorage.setItem('counter', `${SESSION_TIMEOUT_MIN}`)
    localStorage.setItem('LastPageUrl', location?.pathname)
  }, [location])

  useEffect(() => {
    const storedShortcuts = localStorage.getItem('shortcuts')

    if (storedShortcuts) {
      // const parsedShortcuts = JSON.parse(storedShortcuts)
      // const shortcutsToState = parsedShortcuts.map((sc) => ({
      //   ...sc,
      //   icon: sc?.icon?.props?.icon && <Icon icon={sc.icon.props.icon} style={{ color: sc.icon.props.style.color }} />
      // }))
      // bsetShortcuts(shortcutsToState)
    }
  }, [localStorage.getItem('shortcuts')])

  return (
    <>
      <PageHead />
      <Layout className="defaultLayout">
        <Sidebar />
        <Layout className="header_and_content">
          <AppHeader />
          <Content className="app-container">
            {pageTitle && <PageTitle title={pageTitle} />}
            <Outlet />
            <LogoutCatchModal open={visibleLogoutModal} setVisible={setVisibleLogoutModal} />
            {/* {isSidebarCollapsed && (
              <SpeedDial
                options={[
                  {
                    key: 'A',
                    content: shortcuts[0]?.url,
                    tooltip: shortcuts[0]?.url,
                    url: shortcuts[0]?.url,
                    onClick: () => {
                      !isAddShortcutModalOpened && dispatch(setAddShortcutModalVisible(true))
                    },
                    backgroundColor: shortcuts[0]?.color,
                    icon: shortcuts[0]?.icon?.props?.icon,
                    color: shortcuts[0]?.textColor
                  },
                  {
                    key: 'B',
                    content: shortcuts[1]?.url,
                    tooltip: shortcuts[1]?.url,
                    url: shortcuts[1]?.url,
                    onClick: () => {
                      !isAddShortcutModalOpened && dispatch(setAddShortcutModalVisible(true))
                    },
                    backgroundColor: shortcuts[1]?.color,
                    icon: shortcuts[1]?.icon?.props?.icon,
                    color: shortcuts[1]?.textColor
                  },
                  {
                    key: 'C',
                    content: shortcuts[2]?.url,
                    tooltip: shortcuts[2]?.url,
                    url: shortcuts[2]?.url,
                    onClick: () => {
                      !isAddShortcutModalOpened && dispatch(setAddShortcutModalVisible(true))
                    },
                    backgroundColor: shortcuts[2]?.color,

                    icon: shortcuts[2]?.icon?.props?.icon,
                    color: shortcuts[2]?.textColor
                  },
                  {
                    key: 'D',
                    content: shortcuts[3]?.url,
                    tooltip: shortcuts[3]?.url,
                    url: shortcuts[3]?.url,
                    onClick: () => {
                      !isAddShortcutModalOpened && dispatch(setAddShortcutModalVisible(true))
                    },
                    backgroundColor: shortcuts[3]?.color,
                    icon: shortcuts[3]?.icon?.props?.icon,
                    color: shortcuts[3]?.textColor
                  },
                  {
                    key: 'E',
                    content: shortcuts[4]?.url,
                    tooltip: shortcuts[4]?.url,
                    url: shortcuts[4]?.url,
                    onClick: () => {
                      !isAddShortcutModalOpened && dispatch(setAddShortcutModalVisible(true))
                    },
                    backgroundColor: shortcuts[4]?.color,
                    icon: shortcuts[4]?.icon?.props?.icon,
                    color: shortcuts[4]?.textColor
                  }
                ]}
              />
            )} */}
          </Content>
        </Layout>
      </Layout>
      <Loading loading={globalLoadingStates.isLoading} loadingType={LOADING_TYPES.FULLSCREEN} />
    </>
  )
}

export default DefaultLayout
