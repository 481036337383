import * as types from 'src/store/actions/storeTypes'

// import { notificationHeaderList } from 'src/store/actions/notifications/notificationHeaderList';
// import { api } from 'src/store/actions/api';
// import { SERVICES } from 'src/store/constants/serviceConstants';

// Başka bir servis ile çalışacak aksiyon burada tanımlanır.
// Bu aksiyon tetiklenir tetiklenmez reducer'a ilgili typelar gönderilir ve store update edilir.

export const setSidebarStatus = (value) => (dispatch) => {
  dispatch({
    type: `${types.SIDEBAR_STATUS}_${value ? 'PASSIVE' : 'ACTIVE'}`,
    payload: value,
    requestName: types.SIDEBAR_STATUS
  })
}
