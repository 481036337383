import globalLoading from 'src/store/reducers/globalLoading'
import sidebarReducer from 'src/store/reducers/sidebarReducer'
import authUser from 'src/store/reducers/userAuth'

import addShortcutModalReducer from './addShortcutModalReducer'
import ExampleReducer from './exampleReducer'

const reducers = {
  authUser,
  globalLoading,
  ExampleReducer,
  sidebarReducer,
  addShortcutModalReducer
}

export default reducers
