import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import translationsdata from 'src/common/locales/locales.json'

const storageLanguage = localStorage.getItem('language')

function assignValuesToObject(obj, assignLangKey) {
  const newObj = {}

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (Object.prototype.hasOwnProperty.call(obj[key], assignLangKey)) {
        newObj[key] = obj[key][assignLangKey]
      } else {
        newObj[key] = assignValuesToObject(obj[key], assignLangKey)
      }
    } else {
      newObj[key] = obj[key]
    }
  }

  return newObj
}

function clearValues(obj, clearLangKeys, assignLangKey) {
  const newObj = { ...obj }

  clearLangKeys?.forEach((langItem) => {
    for (const key in newObj) {
      if (typeof newObj[key] === 'object') {
        newObj[key] = clearValues(newObj[key], clearLangKeys, assignLangKey)
      } else if (key === langItem) {
        delete newObj[key]
      }
    }
  })

  return assignValuesToObject(newObj, assignLangKey)
}

const TR_JSON = clearValues(translationsdata, ['en'], 'tr')
const EN_JSON = clearValues(translationsdata, ['tr'], 'en')

const resources = {
  tr: {
    translation: TR_JSON
  },
  en: {
    translation: EN_JSON
  }
}

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //   .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: storageLanguage || 'en',
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })

export default i18n
