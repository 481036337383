import { ReactElement } from 'react'

import { Popconfirm as PopconfirmAntd } from 'antd'
import { PopconfirmProps as PopconfirmPropsAntd } from 'antd/lib/popconfirm'
import { TooltipPlacement } from 'antd/lib/tooltip'

import { ICON_LIST } from 'src/common/constants/icon/iconList'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'
import { STATUS } from 'src/common/constants/status/status'

import Icon from 'src/components/icon/Icon'
import Tooltip from 'src/components/tooltip/Tooltip'
import TranslatedText from 'src/components/translated-text/TranslatedText'

interface PopconfirmProps extends Omit<PopconfirmPropsAntd, 'title'> {
  title?: string
  confirmTitle: string
  titleParams?: string[]
  onConfirm
  buttonIcon?
  modalIcon?
  tooltipText?: string
  confirmText?: string
  cancelText?: string
  status?: STATUS
  placement?: TooltipPlacement
  children?: ReactElement | JSX.Element
}

function Popconfirm(props: PopconfirmProps) {
  // Desctruct Props
  const { confirmTitle, tooltipText, onConfirm, confirmText, cancelText, modalIcon, placement, children, titleParams } =
    props

  const popConfirmTemplate = (
    <PopconfirmAntd
      placement={placement || 'topRight'}
      {...props}
      title={<TranslatedText label={confirmTitle} labelParams={titleParams} />}
      icon={modalIcon}
      onConfirm={onConfirm}
      okText={<TranslatedText label={confirmText || 'GLOBAL.FORM_ELEMENTS.CTA.YES'} />}
      cancelText={<TranslatedText label={cancelText || 'GLOBAL.FORM_ELEMENTS.CTA.NO'} />}
      okButtonProps={{
        className: 'btn-success btn-sm',
        icon: <Icon icon={ICON_LIST.CHECK_DEFAULT} size="sm" status={ICON_STATUS.WHITE} marginright={5} />
      }}
      cancelButtonProps={{
        className: 'btn-secondary btn-sm',
        icon: <Icon icon={ICON_LIST.CLOSE} size="sm" status={ICON_STATUS.WHITE} marginright={5} />
      }}
    >
      {children != null && children}
    </PopconfirmAntd>
  )

  return tooltipText ? (
    <Tooltip {...props} title={tooltipText}>
      {popConfirmTemplate}
    </Tooltip>
  ) : (
    popConfirmTemplate
  )
}

export default Popconfirm
