export const normalTheme = () => ({
  styles: {
    // Colors
    primary: '#8a79d6',
    secondary: '#febfb5',
    warning: '#ee8e0e',
    danger: '#ee3124',
    success: '#28a745',
    info: '#94bbf3',
    dark: '#845EC2',
    light: '#FBEAFF',
    white: '#ffffff',
    black: '#000000',
    link: '#845EC2',
    font: '#2b2d38',

    // Branding
    brand_1: '#ff8000',
    brand_2: '#ff6600',
    brand_3: '#ff8000',
    mainBg: '#ffffff',

    // #0f3557,

    // Custom Card Colors
    card_bg_1: '#d65db1',
    card_bg_2: '#ff6f91',
    card_bg_3: '#ff9671',
    card_bg_4: '#ffc75f',

    // Defaults
    radioselect: '#b0bec5',
    radio_back: '#78909c',

    primary_900: '#ff8000',
    primary_800: '#ff8000',
    primary_700: '#ff8000',
    primary_600: '#ff8000',
    primary_500: '#ff8000',
    primary_400: '#ff8000',
    primary_300: '#ff8000',
    primary_200: '#ff8000',
    primary_100: '#ff8000',

    secondary_900: '#57595c',
    secondary_800: '#6d7074',
    secondary_700: '#82868b',
    secondary_600: '#989ca2',
    secondary_500: '#c4c9d0',
    secondary_400: '#dae0e8',
    secondary_300: '#dde3ea',
    secondary_200: '#e1e6ec',
    secondary_100: '#e5e9ee',

    gray_900: '#8c8c8c',
    gray_800: '#a6a6a6',
    gray_700: '#bfbfbf',
    gray_600: '#d9d9d9',
    gray_500: '#ebebeb',
    gray_400: '#f5f5f5',
    gray_300: '#f7f7f7',
    gray_200: '#fafafa',
    gray_100: '#fcfcfc',

    dark_900: '#393a3b',
    dark_800: '#4b4b4b',
    dark_700: '#5e5e5e',
    dark_600: '#707070',
    dark_500: '#828282',
    dark_400: '#949494',
    dark_300: '#a6a6a6',
    dark_200: '#b8b8b8',
    dark_100: '#c9c9c9',

    danger_900: '#841e1e',
    danger_800: '#9a2323',
    danger_700: '#b02728',
    danger_600: '#c62d2d',
    danger_500: '#dc3232',
    danger_400: '#e35b5b',
    danger_300: '#ea8484',
    danger_200: '#f1adac',
    danger_100: '#fcebeb',

    success_900: '#256130',
    success_800: '#2c7336',
    success_700: '#328540',
    success_600: '#399648',
    success_500: '#46b451',
    success_400: '#6bc373',
    success_300: '#90d296',
    success_200: '#b5e1b9',
    success_100: '#edf8ee',

    warning_900: '#dd6318',
    warning_800: '#fbbd33',
    warning_700: '#ee8e0e',
    warning_600: '#f7a408',
    warning_500: '#ffb902',
    warning_400: '#ffc733',
    warning_300: '#ffd567',
    warning_200: '#ffe399',
    warning_100: '#fff8e6',

    info_900: '#1668dc',
    info_800: '#337ee6',
    info_700: '#4d94f0',
    info_600: '#66aaf9',
    info_500: '#80c0ff',
    info_400: '#99d6ff',
    info_300: '#b3ecff',
    info_200: '#cceeff',
    info_100: '#e6f4ff',

    font_900: '#2b2d38',
    font_800: '#4b4b4b',
    font_700: '#6b6b6b',
    font_600: '#8c8c8c',
    font_500: '#acacac',
    font_400: '#cccccc',
    font_300: '#e6e6e6',
    font_200: '#f5f5f5',
    font_100: '#ffffff',

    white_900: '#ffffff',
    white_800: '#ffffff',
    white_700: '#ffffff',
    white_600: '#ffffff',
    white_500: '#ffffff',
    white_400: '#ffffff',
    white_300: '#ffffff',
    white_200: '#ffffff',
    white_100: '#ffffff'
  }
})

export const darkTheme = () => ({
  styles: {
    primary: '#845EC2',
    secondary: '#B39CD0',
    warning: '#b56b50',
    danger: '#a6475d',
    success: '#28a745',
    info: '#5e7ca7',
    dark: '#845EC2',
    light: '#FBEAFF',
    white: '#ffffff',
    black: '#000000',
    link: '#845EC2',
    font: '#ffffff',

    // Branding
    brand_1: '#ee3124',
    brand_2: '#4b4b4b',
    brand_3: '#232b38',
    mainBg: '#22242d',

    // Custom Card Colors - Darker
    card_bg_1: '#7f3869',
    card_bg_2: '#903f52',
    card_bg_3: '#89533f',
    card_bg_4: '#81642f',

    primary_900: '#1f2029',
    primary_800: '#202122',
    primary_700: '#2b2d39',
    primary_600: '#5e5e5e',
    primary_500: '#8c8c8c',
    primary_400: '#b4b4b4',
    primary_300: '#dcdcdc',
    primary_200: '#ebebeb',
    primary_100: '#f5f5f5',

    secondary_900: '#57595c',
    secondary_800: '#6d7074',
    secondary_700: '#82868b',
    secondary_600: '#989ca2',
    secondary_500: '#c4c9d0',
    secondary_400: '#dae0e8',
    secondary_300: '#dde3ea',
    secondary_200: '#e1e6ec',
    secondary_100: '#e5e9ee',

    gray_900: '#2b2d39',
    gray_800: '#393a3b',
    gray_700: '#4b4b4b',
    gray_600: '#5e5e5e',
    gray_500: '#292b35',
    gray_400: '#4f4f4f',
    gray_300: '#dcdcdc',
    gray_200: '#ebebeb',
    gray_100: '#f5f5f5',

    dark_900: '#2b2d39',
    dark_800: '#393a3b',
    dark_700: '#4b4b4b',
    dark_600: '#5e5e5e',
    dark_500: '#8c8c8c',
    dark_400: '#b4b4b4',
    dark_300: '#dcdcdc',
    dark_200: '#ebebeb',
    dark_100: '#f5f5f5',

    danger_900: '#ff4d4f',
    danger_800: '#ff6f71',
    danger_700: '#ff9193',
    danger_600: '#ffb3b5',
    danger_500: '#ffcccc',
    danger_400: '#ffdddd',
    danger_300: '#ffeef0',
    danger_200: '#fff1f1',
    danger_100: '#fff4f4',

    success_900: '#28a745',
    success_800: '#3fbf57',
    success_700: '#56d76a',
    success_600: '#6de07c',
    success_500: '#84e98f',
    success_400: '#9bf2a1',
    success_300: '#b2fbb4',
    success_200: '#c9ffc6',
    success_100: '#e0ffd9',

    warning_900: '#faad14',
    warning_800: '#fbbd33',
    warning_700: '#fccd52',
    warning_600: '#fddd71',
    warning_500: '#fee690',
    warning_400: '#fff0af',
    warning_300: '#fff5cf',
    warning_200: '#fff9ee',
    warning_100: '#fffdfc',

    info_900: '#2b2d39',
    info_800: '#393a3b',
    info_700: '#4b4b4b',
    info_600: '#5e5e5e',
    info_500: '#8c8c8c',
    info_400: '#b4b4b4',
    info_300: '#dcdcdc',
    info_200: '#ebebeb',
    info_100: '#f5f5f5',

    font_900: '#ced4da',
    font_800: '#393a3b',
    font_700: '#4b4b4b',
    font_600: '#5e5e5e',
    font_500: '#8c8c8c',
    font_400: '#b4b4b4',
    font_300: '#dcdcdc',
    font_200: '#ebebeb',
    font_100: '#f5f5f5',

    white_900: '#1d2027',
    white_800: '#393a3b',
    white_700: '#4b4b4b',
    white_600: '#5e5e5e',
    white_500: '#8c8c8c',
    white_400: '#b4b4b4',
    white_300: '#dcdcdc',
    white_200: '#ebebeb',
    white_100: '#f5f5f5'
  }
})
