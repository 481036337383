import React, { ReactElement } from 'react'

import { CUSTOM_ICON_SIZES } from 'src/common/constants/icon/iconSizes'
import { ICON_STATUS } from 'src/common/constants/icon/iconStatus'

export interface IconProps {
  status?: ICON_STATUS
  size?: CUSTOM_ICON_SIZES | number
  name: ReactElement
  style?: React.CSSProperties
  className?: string
}

function CustomIcon(props: IconProps) {
  // Descturct Props
  const { size, name, status, style, className } = props

  return (
    <>
      {React.cloneElement(name, {
        className,
        width: size,
        height: size,
        color: `${status}`,
        style: {
          ...style
        }
      })}
    </>
  )
}

export default CustomIcon

CustomIcon.defaultProps = {
  size: CUSTOM_ICON_SIZES.MEDIUM
}
