/* Konfigüre edeceğimiz servisleri enum olarak yazıyoruz ve gerektiğinde buradan içe aktarıyoruz.  */

export enum SERVICES {
  AUTH = 'auth',
  CHAT = 'chat',
  CRM = 'crm',
  NOTIFICATION = 'notification',
  HISTORY = 'history',
  USER = 'user',
  REPORT = 'report',
  CRM_REPORT = 'crm-report',
  CRM_SOCIAL_POST = 'crm-social-post',
  CRM_SOCIAL_COMMENT = 'crm-social-comment',
  OFFICIAL_ACCOUNT = 'crm-official-account',
  CRM_FILE = 'crm-file',
  CRM_ACADEMY = 'crm/academy'
}
