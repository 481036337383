export const toggleSidebar = (isSidebarCollapsed) => {
  document.querySelector('.shortcut-bar')?.classList.toggle('collapsed')
  document
    .querySelector('.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-has-trigger .ant-layout-sider-children')
    ?.classList.toggle('collapsed')

  document.querySelector('.header_and_content')?.classList.toggle('collapsed')
  document.querySelector('.app-sidebar')?.classList.toggle('collapsed')

  document.querySelectorAll('.ant-menu-item .ant-menu-title-content')?.forEach((element) => {
    element?.classList.toggle('collapsed')
  })

  const elements = document.querySelectorAll('.ant-menu-submenu .ant-menu-sub .ant-menu-item:last-child')
  if (!isSidebarCollapsed) {
    elements.forEach((element: any) => {
      const item = element
      item.style.borderBottomColor = 'transparent'
      item.style.setProperty('border-bottom-color', 'transparent', 'important')
    })
  } else {
    elements.forEach((element: any) => {
      const item = element
      item.style.borderBottomColor = ''
      element.style.setProperty('border-bottom-color', '', 'important')
    })
  }
}
