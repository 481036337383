/* eslint-disable max-len */
export const alphabeticRegex = /^[çÇıİöÖşŞüÜğĞa-zA-Z ]*$/

export const numericRegex = /^[0-9]*$/

export const alphaNumericRegex = /^[a-zA-Z0-9]+$/

export const alphaNumericWithSlashRegex = '^/[a-zA-Z0-9/_-]*$'

export const amountRegex = /^[0-9,]*$/

export const phoneRegex = /^(((\+|00)?(90)|0)[-| ]?)?((5\d{2})[-| ]?(\d{3})[-| ]?(\d{2})[-| ]?(\d{2}))$/gm

export const tripleNumericRegex = /\B(?=(\d{3})+(?!\d))/g

export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i

export const commaRegex = /,/g

export const dotRegex = /\./g

export const websiteRegex =
  /^(http(s)?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/

// export const linkRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
