import { STATUS } from 'src/common/constants/status/status'

import Button from 'src/components/cta/button/Button'
import TranslatedText from 'src/components/translated-text/TranslatedText'

import notFoundImage from 'src/assets/img/err_404.svg'

function NotFound() {
  return (
    <div className="errorPageWrapper">
      <img src={notFoundImage} alt="404 Page Not Found" />
      <p className="margin_bottom_10">
        <TranslatedText label="GLOBAL.PAGES.NOT_FOUND.DESCRIPTION" />
      </p>

      <Button
        name="back_to_home_cta"
        status={STATUS.PRIMARY}
        htmlType="submit"
        push="/"
        label="GLOBAL.FORM_ELEMENTS.CTA.BACK_TO_HOME"
      />
    </div>
  )
}

export default NotFound
