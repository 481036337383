import {
  addDays,
  addMinutes,
  addMonths,
  addYears,
  endOfDay,
  format,
  formatISO,
  getDate as getDateFNS,
  getDay as getDayFNS,
  getHours as getHoursFNS,
  getMinutes,
  isAfter as isAfterFNS,
  isBefore,
  isDate as isDateFNS,
  isWithinInterval,
  parseISO,
  setDay as setDayFNS,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay,
  subDays,
  subMinutes,
  toDate
} from 'date-fns'
import { enUS, tr } from 'date-fns/locale'
import i18n from 'src/common/locales/i18n'

import {
  dateFormat,
  dateFormatBackend,
  dateHour,
  dateTimeAnnoFormat,
  dateTimeFormat,
  dateTimeMiliSecondFormatBackend,
  dateTimeSecondFormat,
  dateTimeSecondFormatFileName,
  dateTimeSecondMiliFormat,
  dayMonthYearFormat,
  dayMonthYearTimeFormat,
  monthFormatToBackend,
  monthYearFormat,
  timeFormat
} from 'src/common/constants/date/dateFormats'

// GetDays
export const getYesterday = () => subDays(new Date(), 1)
export const getToday = (format?) => (format ? new Date(format) : new Date())
export const getTomorrow = () => addDays(new Date(), 1)
export const getTodayFileName = () => format(new Date(), dateTimeSecondFormatFileName)
export const getSubtractDays = (count) => subDays(new Date(), count)
export const getSubtractMinutes = (date, count) => subMinutes(date, count)
export const getStartOfDay = () => startOfDay(new Date())
export const getEndOfDay = () => endOfDay(new Date())

// Get Now
export const getNowHour = () => getHoursFNS(new Date())
export const getNowMinutes = () => getMinutes(new Date())

// Set Date
export const setDay = (date, day) => setDayFNS(date, day)
export const setHour = (date, hour) => setHours(date, hour)
export const setMinute = (date, minute) => setMinutes(date, minute)
export const setSecond = (date, second) => setSeconds(date, second)

// Get Specific Moments
export const getDate = (date) => getDateFNS(date)
export const getDay = (date) => getDayFNS(date)
export const getMinute = (date) => getMinutes(date)
export const getHours = (date) => getHoursFNS(date)

// Add Date
export const subtractDay = (date, count) => subDays(date, count)
export const addMinute = (date, count) => addMinutes(date, count)
export const addDay = (count) => addDays(new Date(), count)
export const addMonth = (count) => addMonths(new Date(), count)
export const addYear = (count) => addYears(new Date(), count)

// Formatted to Dates
export const getFormattedDate = (date, dateformat?) => format(date, dateformat || dateFormat)
export const getFormattedDateTime = (date, dateformat?) => format(date, dateformat || dateTimeFormat)
export const getFormattedHour = (date, dateformat?) => format(date, dateformat || dateHour)
export const getFormattedMonth = (date, dateformat?) => format(date, dateformat || monthYearFormat)

// Backend To Component
export const backendToDate = (date) => parseISO(date)
export const backendToDateWithFormat = (date, dateformat) => format(parseISO(date), dateformat)

export const stringToTime = (timeString) => {
  const [hours, minutes] = timeString.split(':')

  const date = new Date()
  date.setHours(hours)
  date.setMinutes(minutes)

  return date
}

export const lastUpdates = (date) =>
  format(new Date(date), dayMonthYearTimeFormat, {
    locale: i18n?.language === 'en' ? enUS : tr
  })

export const activityDateFormatter = (date) =>
  format(new Date(date), dayMonthYearFormat, {
    locale: i18n?.language === 'en' ? enUS : tr
  })

export const monthlyScheduleDateFormatter = (date) =>
  format(new Date(date), dayMonthYearFormat, {
    locale: i18n?.language === 'en' ? enUS : tr
  })

// To Backend Formatters
export const dateToBackend = (date) => format(date, dateFormatBackend)
export const timeToBackend = (date) => format(date, timeFormat)
export const monthToBackend = (date) => format(date, monthFormatToBackend)
export const dateToBackendAnnoTime = (date) => format(date, dateTimeAnnoFormat)
export const dateTimeToBackend = (date) => formatISO(date)
export const dateTimeMiliSecondToBackend = (date) => format(date, dateTimeMiliSecondFormatBackend)

// Stamp
export const stampToString = (date, dateformat?) => format(date, dateformat || dateTimeFormat)
export const stampToStringSeconds = (date, dateformat?) => format(date, dateformat || dateTimeSecondFormat)
export const stampToStringMiliSeconds = (date, dateformat?) => format(date, dateformat || dateTimeSecondMiliFormat)
export const stampToDate = (date) => toDate(date)

// Controls
export const isDate = (value) => isDateFNS(value)
export const isBeforeToday = (value) => isBefore(value, getStartOfDay())
export const isBetween = (date, startDate, endDate) => isWithinInterval(date, { start: startDate, end: endDate })
export const isAfter = (date, date2) => isAfterFNS(date, date2)

export const getDateFromHours = (time) => {
  const newTime = time.split(':')
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate(), ...newTime)
}
