// Status Constants
export enum ICON_STATUS {
  SUCCESS = '#28a745',
  DANGER = ' #dc3545',
  WARNING = '#ffc300',
  INFO = '#65c6f3',
  PRIMARY = '#1890ff',
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  LIGHT_GREY = '#f0f0f0',
  DARK = '#393a3b',
  SECONDARY = '#6c757d',
  BRAND_1 = '#eb690b',
  BRAND_2 = '#00458f',
  DARK_GREY = '#495057'
}
