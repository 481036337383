import LoginForm from './_partials/LoginForm'

import 'src/assets/styles/_login.scss'

function Login() {
  return (
    <div>
      {/* LOGIN */}
      <LoginForm />
    </div>
  )
}

export default Login
