import { applyMiddleware, combineReducers, legacy_createStore as createStore } from 'redux'
import { persistStore } from 'redux-persist'
import { thunk } from 'redux-thunk'
import Reducers from 'src/store/reducers'

/* combineReducers fonksiyonunu kullanarak, tüm reducerları birleştirip tek bir ana reducer oluşturuyoruz. Bu, Redux'un durumunu yönetmek için kullanacağımız ana reducerdır. */
const reducer = combineReducers(Reducers)

/* Redux'un genişletilmiş tür tanımlarını (type declarations) yapılandırıyoruz. Bu, Redux'un asenkron eylemleri (async actions) ve ThunkAction'ları anlayabilmesini sağlar. */
// declare module 'redux' {
//   interface Dispatch<A extends Action = AnyAction> {
//     <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R
//   }
// }

/* Redux Geliştirici Araçları'nın etkin olup olmadığını kontrol ediyoruz. Eğer etkinse, Redux DevTools'u etkinleştiriyoruz. Aksi takdirde, bir boş fonksiyon (identity function) döndürüyoruz. */
// const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

/* Redux store'unu oluşturmak için kullanacağımız enhancer'ı belirliyoruz. Bu enhancer, Redux Thunk ara yazılımını ve geliştirici araçlarını (DevTools) içerir. */

const middlewareEnhancer = applyMiddleware(thunk)
// const composedEnhancers = composeWithDevTools(middlewareEnhancer)

// Create Store
/* Redux store'unu, önceki adımlarda oluşturduğumuz ana reducer ve enhancer ile oluşturuyoruz. */
export const store = createStore(reducer, middlewareEnhancer)

/* redux-persist kütüphanesini kullanarak Redux store'unu kalıcı hale getiriyoruz. Bu, uygulama verilerinin local storage veya başka bir depolama yöntemiyle saklanmasını sağlar. */
export const persistor = persistStore(store)

/* RootState adında bir tür tanımlıyoruz, bu tür Redux store'unun state türünü yansıtır. Bu tür, bileşenlerde Redux state'ine erişmek için kullanılabilir. */
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
