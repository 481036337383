import React from 'react'

import { Modal as ModalAntd } from 'antd'
import { ModalProps as ModalPrpsAntd } from 'antd/lib/modal'

import { ICON_LIST } from 'src/common/constants/icon/iconList'

import { translate } from 'src/common/utils/translateUtil'

import Icon from 'src/components/icon/Icon'
import TranslatedText from 'src/components/translated-text/TranslatedText'

import 'src/assets/styles/_modal.scss'

export interface ModalProps extends ModalPrpsAntd {
  onCancel?: () => void | undefined
  onOk?: (values?) => void | undefined
  open: boolean | undefined
  title?: string
  customTitle?: string
  titleParams?: string[]
  children: any
  footer?: React.ReactNode
  width?: string | number
}

function Modal(props: ModalProps) {
  // Desctruct Props
  const { children, onCancel, onOk, open, title, customTitle, footer, titleParams, cancelText, okText } = props

  return (
    <ModalAntd
      {...props}
      title={customTitle || (title && <TranslatedText label={title} labelParams={titleParams} />)}
      onCancel={onCancel}
      onOk={onOk}
      cancelText={cancelText && translate(cancelText as string)}
      okText={okText && translate(okText as string)}
      closeIcon={<Icon icon={ICON_LIST.CLOSE} size="lg" />}
      open={open}
      footer={footer}
      destroyOnClose
    >
      {children}
    </ModalAntd>
  )
}

export default Modal
